<template>
  <v-stepper
    v-model="currentStep"
    class="stepper-outer-container"
    :key="rerender"
  >
    <div class="designer-progress-circle" v-if="isSendingToApi">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <v-stepper-header v-if="currentStep > 1">
      <template v-for="n in getStepCount">
        <v-stepper-step
          @click="checkToReset(n)"
          :key="`${n}-step`"
          :complete="currentStep > n"
          :step="n"
          :editable="currentStep > n"
        >
          {{ getStepperTitle(n) }}
        </v-stepper-step>

        <v-divider v-if="n !== getStepCount" :key="n"></v-divider>
      </template>
    </v-stepper-header>

    <v-stepper-items>
    <div class="stepper-content-container">
        <div class="summary-container" v-if="currentStep > EVENT_MODE_STEP">
            <v-card elevation="2" outlined tile>
                <v-card-title>{{ $t("summary.title") }}</v-card-title>
                <v-card-text>
                    <div class="result" v-for="(item, index) in getSummary()" :key="index">
                        <div v-html="parseSummary(item)"></div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div
            :class="`stepper-container ${
            currentStep <= EVENT_MODE_STEP ? 'stepper-container-full' : ''
            }`"
        >
            <v-expansion-panels
                class="summary-mobile-container"
                v-if="currentStep > EVENT_MODE_STEP"
                accordion
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <div class="summary-mobile-header">
                            {{ $t("summary.title") }}
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="summary-mobile-content">
                        <div class="result" v-for="(item, index) in getSummary()" :key="index">
                            <div v-html="parseSummary(item)"></div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-stepper-content
                :step="EVENT_MODE_STEP"
                v-if="currentStep === EVENT_MODE_STEP"
            >
                <v-container>
                    <event-mode
                        :status="result"
                        v-on:summary="onSummary($event)"
                        v-on:next="nextFromEventMode($event)"
                        v-on:eventModeChange="onEventModeChange($event)"
                        v-on:error="onError($event)"
                        :eventModeBus="eventModeBus"
                    />
                </v-container>
            </v-stepper-content>

            <v-stepper-content
                :step="getSelectionStep"
                v-if="currentStep === getSelectionStep"
            >
                <v-container>
                    <selection
                        :status="result"
                        v-on:summary="onSummary($event)"
                        v-on:next="next()"
                        v-on:selectionChange="onSelectionChange($event)"
                        v-on:error="onError($event)"
                        :bus="selectionBus"
                    />
                </v-container>
            </v-stepper-content>

            <v-stepper-content
                :step="getUpgradesStep"
                v-if="currentStep === getUpgradesStep"
            >
                <v-container>
                    <upgrades
                        :status="result"
                        v-on:summary="onSummary($event)"
                        v-on:next="next()"
                        v-on:upgradesChange="onUpgradesChange()"
                        v-on:error="onError($event)"
                        :bus="upgradesBus"
                    />
                </v-container>
            </v-stepper-content>

            <v-stepper-content
                :step="getLocationStep"
                v-if="currentStep === getLocationStep"
            >
                <v-container>
                    <location
                        :status="result"
                        v-on:summary="onSummary($event)"
                        v-on:next="next()"
                        v-on:locationChange="onLocationChange($event)"
                        v-on:error="onError($event)"
                        :bus="locationBus"
                    />
                </v-container>
            </v-stepper-content>

            <v-stepper-content
                :step="getDataStep"
                v-if="currentStep === getDataStep"
            >
                <v-container>
                    <data-step
                        :status="result"
                        :memory="dataMemory"
                        v-on:memory="onMemory($event)"
                        v-on:summary="onSummary($event)"
                        v-on:next="next()"
                        v-on:dataChange="onDataChange($event)"
                        v-on:error="onError($event)"
                        :bus="dataBus"
                    />
                </v-container>
            </v-stepper-content>
        </div>
    </div>
</v-stepper-items>

  </v-stepper>
</template>

<script>
import EventMode from "../components/EventMode.vue";
import Selection from "../components/Selection.vue";
import Upgrades from "./../components/Upgrades.vue";
import Location from "./../components/Location.vue";
import DataStep from "./../components/Data.vue";
import {
  EVENT_MODE_LOCAL_FUN_AR_RALLYE,
  EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN,
  EVENT_MODE_ONLINE_FUN,
  EVENT_MODE_ONLINE_CONTENT,
  PRODUCT_AR_RALLYE,
  PRODUCT_THE_PERFECT_RUN,
  PRODUCT_CITY_CHALLENGE,
  PRODUCT_TEAM_CHALLENGE,
  PRODUCT_EVENT_MARKETING,
  ERROR_SERVER,
  LOCAL_STORAGE_GUID,
} from "../utils/constants";
import { buildRedirectLink, translate } from "../utils/helper";
import {
  fetchThankYouLink,
  logActivity,
  REQUEST_FAILED,
} from "../utils/server";
import axios from "axios";
import {
  navigationBus,
  eventModeBus,
  selectionBus,
  upgradesBus,
  locationBus,
  dataBus,
} from "./../main";
const uuid = require("uuid");

const MAX_STEP_COUNT = 5;
const UNKNOWN_STEP = 0;
const EVENT_MODE_STEP = 1;
const SELECTION_STEP = 2;
const UPGRADES_STEP = 3;
const LOCATION_STEP = 4;
const DATA_STEP = 5;

export default {
  name: "Designer",
  components: {
    EventMode,
    Selection,
    Upgrades,
    Location,
    DataStep,
  },
  data() {
    return {
      eventModeBus,
      selectionBus,
      upgradesBus,
      locationBus,
      dataBus,
      EVENT_MODE_STEP,
      rerender: 0,
      currentStep: EVENT_MODE_STEP,
      steps: {
        eventMode: true,
        selection: true,
        upgrades: true,
        location: true,
        data: true,
      },
      result: {},
      summary: {},
      chosenEventMode: null,
      dataMemory: {},
      isSendingToApi: false,
    };
  },
  watch: {
    $route(to, from) {
      let fromStep = 0;
      if (from.query["step"]) {
        fromStep = parseInt(from.query["step"]);
      }
      let toStep = 0;
      if (to.query["step"]) {
        toStep = parseInt(to.query["step"]);
      }
      if (fromStep > toStep) {
        this.onBack();
      }
    },
  },
  created() {
    this.logActivity("ENTRY");
    const self = this;
    navigationBus.$on("next", () => {
      self.emitNavigationToChildBus("next");
    });
    navigationBus.$on("back", () => {
      self.onBack();
    });
  },
  computed: {
    getSelectionStep() {
      if (this.currentStep === EVENT_MODE_STEP) {
        return SELECTION_STEP;
      }
      if (this.noSelection()) {
        return UNKNOWN_STEP;
      }
      return SELECTION_STEP;
    },
    getUpgradesStep() {
      if (this.currentStep === EVENT_MODE_STEP) {
        return UPGRADES_STEP;
      }
      if (this.noSelection()) {
        return SELECTION_STEP;
      }
      return UPGRADES_STEP;
    },
    getLocationStep() {
      if (this.currentStep === EVENT_MODE_STEP) {
        return LOCATION_STEP;
      }
      if (this.noLocation()) {
        return UNKNOWN_STEP;
      }
      if (this.noSelection()) {
        return UPGRADES_STEP;
      }
      return LOCATION_STEP;
    },
    getDataStep() {
      if (this.currentStep === EVENT_MODE_STEP) {
        return DATA_STEP;
      }
      if (this.noLocation() || this.noSelection()) {
        return LOCATION_STEP;
      }
      return DATA_STEP;
    },
    getStepCount() {
      let stepCount = Object.values(this.steps).length;
      for (const value of Object.values(this.steps)) {
        if (!value) {
          stepCount--;
        }
      }
      return stepCount;
    },
    getSummaryKeys() {
      let result = [];
      if (this.currentStep > EVENT_MODE_STEP) {
        result.push("eventtype", "focus", "program");
      }
      if (
        this.getSelectionStep > 0 &&
        this.currentStep > this.getSelectionStep
      ) {
        result.push("selection");
      }
      if (this.getUpgradesStep > 0 && this.currentStep > this.getUpgradesStep) {
        result.push("upgrades");
      }
      if (this.getLocationStep > 0 && this.currentStep > this.getLocationStep) {
        result.push("location");
      }
      return result;
    },
  },
  methods: {
    back() {
      this.currentStep--;
      this.checkToReset(this.currentStep);
      this.rerender++;
      this.scrollUp();
    },
    buildNavigateToThankYouLink(thankYouLink) {
      const firstName = encodeURIComponent(this.result.data.first_name);
      const lastName = encodeURIComponent(this.result.data.last_name);
      const email = encodeURIComponent(this.result.data.email);
      return (
        buildRedirectLink(this.$i18n.locale, thankYouLink) +
        `&user_first_name=${firstName}&user_last_name=${lastName}&user_mail=${email}`
      );
    },
    checkToReset(step) {
      if (step === EVENT_MODE_STEP) {
        this.resetSteps();
        this.resetSummary();
      }
      if (step < this.currentStep) {
        this.currentStep = step;
        this.rerender++;
      }
    },
    emitNavigationToChildBus(event) {
      if (this.currentStep === EVENT_MODE_STEP) {
        eventModeBus.$emit(event);
      } else if (this.currentStep === this.getSelectionStep) {
        selectionBus.$emit(event);
      } else if (this.currentStep === this.getUpgradesStep) {
        upgradesBus.$emit(event);
      } else if (this.currentStep === this.getLocationStep) {
        locationBus.$emit(event);
      } else {
        dataBus.$emit(event);
      }
    },
    getGuid() {
      let result = localStorage.getItem(LOCAL_STORAGE_GUID);
      if (result == null) {
        result = uuid.v4();
        localStorage.setItem(LOCAL_STORAGE_GUID, result);
      }
      return result;
    },
    getStepperTitle(step) {
      let rest = step;
      for (let i = EVENT_MODE_STEP; i <= MAX_STEP_COUNT; ++i) {
        if (!Object.values(this.steps)[i - 1]) {
          continue;
        }
        rest--;
        if (rest > 0) {
          continue;
        }
        if (i === EVENT_MODE_STEP) {
          return this.$t("steps.event-mode");
        }
        if (i === SELECTION_STEP) {
          return this.$t("steps.selection");
        }
        if (i === UPGRADES_STEP) {
          return this.$t("steps.upgrades");
        }
        if (i === LOCATION_STEP) {
          return this.$t("steps.location");
        }
      }
      return this.$t("steps.data");
    },
    getSummary() {
      let result = [];
      const summaryKeys = this.getSummaryKeys;
      for (const [key, value] of Object.entries(this.summary)) {
        if (summaryKeys.includes(key)) {
          result.push(value);
        }
      }
      return result;
    },
    isLocalFunProduct() {
      return (
        this.result.product &&
        this.result.product.length === 1 &&
        (this.result.product[0] === PRODUCT_CITY_CHALLENGE ||
          this.result.product[0] === PRODUCT_TEAM_CHALLENGE ||
          this.result.product[0] === PRODUCT_AR_RALLYE ||
          this.result.product[0] === PRODUCT_THE_PERFECT_RUN ||
          this.result.product[0] === PRODUCT_EVENT_MARKETING)
      );
    },
    async logActivity(step) {
      try {
        await logActivity(this.getGuid(), step);
      } catch (error) {
        console.log(error);
      }
    },
    async navigateToThankYou() {
      const linkResponse = await fetchThankYouLink(this.chosenEventMode.enum);
      if (linkResponse.length !== 1) {
        throw REQUEST_FAILED;
      }
      window.location.href = this.buildNavigateToThankYouLink(
        linkResponse[0].thank_you_link
      );
    },
    async next(fromEventMode) {
      if (this.currentStep > EVENT_MODE_STEP) {
        let step = 0;
        if (this.$route.query["step"]) {
          step = parseInt(this.$route.query["step"]);
        }
        step++;
        this.$router.push(
          this.$route.path +
            "?language=" +
            this.$route.query["language"] +
            "&step=" +
            step
        );
        if (this.currentStep == this.getSelectionStep) {
          await this.logActivity("SELECTION");
        } else if (this.currentStep == this.getUpgradesStep) {
          await this.logActivity("UPGRADES");
        } else if (this.currentStep == this.getLocationStep) {
          await this.logActivity("LOCATIONS");
        } else if (this.currentStep == this.getDataStep) {
          await this.logActivity("DATA");
        }
      } else {
        await this.logActivity("MODE");
      }
      this.rerender++;
      if (this.currentStep === this.getDataStep) {
        try {
          this.isSendingToApi = true;
          await this.sendToApi();
          await this.logActivity("FINISH");
          await this.navigateToThankYou();
        } catch (error) {
          this.isSendingToApi = false;
          if (error === ERROR_SERVER) {
            this.onError(error);
          } else {
            this.onErrorWithMessage(error);
          }
          this.scrollUp();
        }
      } else {
        if (fromEventMode && this.currentStep >= SELECTION_STEP) {
          console.log("Next from event mode was already fired!");
          return;
        }
        this.currentStep++;
        this.scrollUp();
      }
    },
    nextFromEventMode(chosenEventMode) {
      this.resetSteps();
      this.chosenEventMode = chosenEventMode;
      if (this.noLocation()) {
        this.steps.location = false;
      }
      if (this.noSelection()) {
        this.steps.selection = false;
      }
      this.next(true);
    },
    onBack() {
      if (this.currentStep === EVENT_MODE_STEP) {
        this.emitNavigationToChildBus("back");
      } else {
        this.back();
      }
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    onErrorWithMessage(error) {
      console.log(error);
      this.$emit("error", error);
    },
    noSelection() {
      return (
        this.chosenEventMode !== null &&
        (this.chosenEventMode.enum === EVENT_MODE_LOCAL_FUN_AR_RALLYE ||
          this.chosenEventMode.enum === EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN)
      );
    },
    noLocation() {
      return (
        this.chosenEventMode !== null &&
        (this.chosenEventMode.enum === EVENT_MODE_ONLINE_FUN ||
          this.chosenEventMode.enum === EVENT_MODE_ONLINE_CONTENT)
      );
    },
    onDataChange(continueDisabled) {
      this.$emit("dataChange", continueDisabled);
    },
    onEventModeChange(floatingButtonVisible) {
      this.$emit("eventModeChange", floatingButtonVisible);
    },
    onLocationChange(continueDisabled) {
      this.$emit("locationChange", continueDisabled);
    },
    onMemory(data) {
      this.dataMemory = data;
    },
    onSelectionChange(continueDisabled) {
      this.$emit("selectionChange", continueDisabled);
    },
    onSummary(summary) {
      if (summary.content) {
        this.summary[summary.key] = summary;
      }
      for (let item of summary.values) {
        this.result[item.title] = item.content;
      }
    },
    onUpgradesChange() {
      this.$emit("upgradesChange");
    },
    parseSummary(summary) {
      if (summary) {
        return `<b>${this.$t(summary.title)}:</b> ${this.translate(
          summary.content
        )}`;
      }
      return null;
    },
    resetSteps() {
      this.steps.eventMode = true;
      this.steps.selection = true;
      this.steps.upgrades = true;
      this.steps.location = true;
      this.steps.data = true;
    },
    resetSummary() {
      let newResult = {};
      newResult["eventtype"] = this.result["eventtype"];
      newResult["focus"] = this.result["focus"];
      if (this.isLocalFunProduct()) {
        newResult["product"] = this.result["product"];
      }
      this.result = newResult;
      let newSummary = {};
      newSummary["eventtype"] = this.summary["eventtype"];
      newSummary["focus"] = this.summary["focus"];
      this.summary = newSummary;
    },
    scrollUp() {
      window.scrollTo(0, 0);
    },
    async sendToApi() {
      if (this.result["data"]["language"] == "") {
        this.result["language"] = this.$i18n.locale;
      } else {
        this.result["language"] = this.result["data"]["language"];
      }
      try {
        const result = await axios.post(
          "https://app.teamazing.com/api/inquiry_request",
          this.result
        );
        if (result.status !== 200) {
          throw JSON.stringify(result);
        }
        return result;
      } catch (error) {
        console.log(error);
        throw ERROR_SERVER;
      }
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
.v-stepper {
  box-shadow: none !important;
}
.v-stepper__content {
  padding: 0.5rem !important;
}
.v-expansion-panel {
  border-radius: 0 !important;
}
.v-expansion-panel-header {
  border-radius: 0 !important;
}

.designer-progress-circle {
  width: 100%;
  height: 100vh;
  background-color: #00000088;
  position: fixed;
  z-index: 1000;
}
.designer-progress-circle * {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stepper-content-container {
  height: 100%;
  flex-grow: 1;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: row;
}
.stepper-outer-container {
  height: 100%;
}
.summary-container {
  width: 33%;
  position: absolute;
  z-index: 1;
  padding: 1rem;
  margin-top: 0.25rem;
  padding-right: 0;
  height: 100%;
  background-color: white;
  display: none;
}
.summary-mobile-container {
  width: 100%;
}
.summary-mobile-content {
  font-size: 0.875rem;
  color: rgb(0, 0, 0, 0.6);
}
.summary-mobile-header {
  font-weight: 500;
  font-size: 1.25rem;
}
.stepper-container {
  margin-left: 0;
  width: 100%;
}
@media (min-width: 52.5rem) {
  .stepper-container {
    margin-left: 30%;
    width: 70%;
  }
  .summary-container {
    display: inline-block;
    width: 30%;
  }
  .summary-mobile-container {
    display: none;
  }
}
@media (min-width: 64rem) {
  .stepper-container {
    margin-left: 25%;
    width: 75%;
  }
  .summary-container {
    width: 25%;
  }
}
@media (min-width: 81.25rem) {
  .stepper-container {
    margin-left: 20%;
    width: 80%;
  }
  .summary-container {
    width: 20%;
  }
}
.stepper-container-full {
  margin-left: 0;
  width: 100%;
}
</style>
