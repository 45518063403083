<template>
  <v-app>
    <v-app-bar app color="black">
      <div>
        <v-img
          alt="Logo teamazing"
          class="app-logo-image cursor-pointer"
          contain
          min-width="150"
          :src="require('./assets/logo_white.png')"
          width="150"
          @click="onShowResetDialog()"
        />
        <v-img
          alt="Icon teamazing"
          class="app-icon-image cursor-pointer"
          contain
          max-height="40"
          max-width="40"
          :src="require('./assets/icon_white.png')"
          width="150"
          @click="onShowResetDialog()"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn
        class="app-header-help-button"

        target="_blank"
        color="white"
        :href="getTeamazingHomepageLink"
        data-tf-position="left"
        data-tf-opacity="100"
        data-tf-iframe-props="title=Zurück zur Homepage"
        text
      >
        <span class="mr-2">{{ $t("back-to") }} {{ getTeamazingHomepageLinkShort }}</span>
        <v-icon>mdi-share</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="white" text v-bind="attrs" v-on="on">
            <span class="mr-2">{{
              isGermanLanguage
                ? $t("header.languages.german")
                : $t("header.languages.english")
            }}</span>
            <v-list-item-avatar size="24" class="header-language-flag">
              <v-img
                :src="
                  isGermanLanguage
                    ? require('./assets/german-flag.svg')
                    : require('./assets/british-flag.svg')
                "
              ></v-img>
            </v-list-item-avatar>
          </v-btn>
        </template>
        <v-list dark>
          <v-list-item @click="changeLanguage(LOCALE_GERMAN)">
            <v-list-item-title>{{
              $t("header.languages.german")
            }}</v-list-item-title>
            <v-list-item-avatar size="24">
              <v-img :src="require('./assets/german-flag.svg')"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item @click="changeLanguage(LOCALE_ENGLISH)">
            <v-list-item-title>{{
              $t("header.languages.english")
            }}</v-list-item-title>
            <v-list-item-avatar size="24">
              <v-img :src="require('./assets/british-flag.svg')"></v-img>
            </v-list-item-avatar>
          </v-list-item>
        </v-list>
      </v-menu>
      
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
            class="app-header-mobile-menu"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list dark class="app-header-mobile-menu">
          <v-list-item @click="openLinkBlank(getTeamazingLink)">
            <v-list-item-title>{{ $t("footer.homepage") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openLinkBlank(getHelpLink)">
            <v-list-item-title>{{ $t("help") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openLinkBlank(getImprintLink)">
            <v-list-item-title>{{ $t("footer.imprint") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openLinkBlank(getPrivacyLink)">
            <v-list-item-title>{{ $t("footer.privacy") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="openLinkBlank(getTermsAndConditionsLink)">
            <v-list-item-title>{{
              $t("footer.terms-and-conditions")
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="app-header-help-button"

        target="_blank"
        color="white"
        :data-tf-slider="getHelpTypeformId"
        data-tf-position="left"
        data-tf-opacity="100"
        data-tf-iframe-props="title=Jetzt anfragen"
        text
      >
        <span class="mr-2">{{ $t("help") }}</span>
        <v-icon>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <designer
        v-on:eventModeChange="onEventModeChange($event)"
        v-on:selectionChange="onSelectionChange($event)"
        v-on:upgradesChange="onUpgradesChange()"
        v-on:locationChange="onLocationChange($event)"
        v-on:dataChange="onDataChange($event)"
        v-on:error="onError($event)"
      />
    </v-main>
    <v-snackbar
      v-model="showErrorSnackbar"
      :multi-line="true"
      :timeout="ERROR_SNACKBAR_DURATION"
    >
      {{ errorSnackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showErrorSnackbar = false"
        >
          {{ $t("ok") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showCookieNotice"
      :multi-line="true"
      timeout="-1"
      vertical
    >
      {{ $t("cookie-notice.text") }}
      <template v-slot:action="{ attrs }">
        <div class="cookie-notice-snackbar-button-container">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="onShowCookieDialog()"
          >
            {{ $t("cookie-notice.adapt") }}
          </v-btn>
          <v-btn color="white" text v-bind="attrs" @click="onAgreeNoCookies()">
            {{ $t("cookie-notice.agree-nothing") }}
          </v-btn>
          <v-btn
            color="primary"
            text
            v-bind="attrs"
            @click="onAgreeAllCookies()"
          >
            {{ $t("cookie-notice.agree-all") }}
          </v-btn>
        </div>
      </template>
    </v-snackbar>
    <cookie-dialog
      :show="showCookieDialog"
      v-on:hide="onHideCookieDialog()"
      v-on:save="onSaveCookieDialog($event)"
    />
    <reset-dialog :show="showResetDialog" v-on:hide="onHideResetDialog()" />
    <floating-navigation
      v-if="floatingNavigation.visible"
      :continueDisabled="floatingNavigation.continueDisabled"
      :continueText="floatingNavigation.continueText"
      v-on:back="goBack()"
      v-on:continue="goNext()"
    />
    <v-footer padless>
      <div class="app-footer flex-row">
        <div class="app-footer-container-mobile">
          ©&nbsp;{{ new Date().getFullYear() }}&nbsp;<a
            class="a-no-decoration"
            :href="getTeamazingLink"
            target="_blank"
            ><strong>{{ $t("teamazing") }}</strong></a
          >&nbsp;{{ version }}
        </div>
        <div class="app-footer-container">
          <div>
            © {{ $t("footer.copyright") }} {{ new Date().getFullYear() }}
          </div>
          <div>&nbsp;—&nbsp;</div>
          <a class="a-no-decoration" :href="getTeamazingLink" target="_blank"
            ><strong>{{ $t("teamazing") }}</strong></a
          >
          <div>&nbsp;—&nbsp;</div>
          <div>
            <a class="a-no-decoration" :href="getImprintLink" target="_blank">{{
              $t("footer.imprint")
            }}</a>
          </div>
          <div>&nbsp;—&nbsp;</div>
          <a class="a-no-decoration" :href="getPrivacyLink" target="_blank">{{
            $t("footer.privacy")
          }}</a>
          <div>&nbsp;—&nbsp;</div>
          <div>
            <a
              class="a-no-decoration"
              :href="getTermsAndConditionsLink"
              target="_blank"
              >{{ $t("footer.terms-and-conditions") }}</a
            >
          </div>
          <div>&nbsp;—&nbsp;</div>
          <div>{{ version }}</div>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import {
  DOMAIN_AT,
  DOMAIN_DE,
  ERROR_SNACKBAR_DURATION,
  LOCALE_ENGLISH,
  LOCALE_GERMAN,
  LOCAL_STORAGE_COOKIE_NOTICE_ACCEPTED,
} from "./utils/constants";
import {
  buildRedirectLink,
  guessDomainWithLanguage,
  guessLocale,
} from "./utils/helper";
import FloatingNavigation from "./gui-components/FloatingNavigation.vue";
import Designer from "./views/Designer.vue";
import CookieDialog from "./components/dialogs/CookieDialog.vue";
import ResetDialog from "./components/dialogs/ResetDialog.vue";
import { navigationBus } from "./main";
import { fetchVersion } from "./utils/server";

export default {
  name: "App",
  components: {
    CookieDialog,
    ResetDialog,
    Designer,
    FloatingNavigation,
  },
  data() {
    return {
      ERROR_SNACKBAR_DURATION,
      LOCALE_ENGLISH,
      LOCALE_GERMAN,
      allowGoogleAnalytics: false,
      allowLiveChat: false,
      errorSnackbarText: this.$t("error-text"),
      floatingNavigation: {
        visible: false,
        continueDisabled: true,
        continueText: this.$t("continue"),
      },
      showCookieNotice: false,
      showCookieDialog: false,
      showResetDialog: false,
      showErrorSnackbar: false,
      version: "v1.06.6",
      versionAsNumber: 10643,
    };
  },
  created() {
    Vue.prototype.$query = this.$route.query;
  },
  mounted() {
    this.checkVersion();
    this.checkCookieNotice();
    this.setLanguage();
  },
  computed: {
    isGermanLanguage() {
      return this.$root.$i18n.locale === "de";
    },
    getHelpTypeformId() {
      const locale = this.$root.$i18n.locale; // Get the current locale
      const domain = guessDomainWithLanguage(locale); // Calculate the domain based on the locale

      switch (domain) {
        case DOMAIN_AT:
          return "AQGgIHgv";
        case DOMAIN_DE:
          return "AQGgIHgv";
        default:
          return "VoY02Jvm";
      }
    },
    getImprintLink() {
      return buildRedirectLink(
        this.$i18n.locale,
        "https://www.teamazing.com/imprint/"
      );
    },
    getPrivacyLink() {
      return buildRedirectLink(
        this.$i18n.locale,
        "https://www.teamazing.com/privacy-policy/"
      );
    },
    getTeamazingLink() {
      return buildRedirectLink(this.$i18n.locale, "https://www.teamazing.com");
    },
    getTeamazingHomepageLink() {
      const locale = this.$root.$i18n.locale; // Get the current locale
      const domain = guessDomainWithLanguage(locale); // Calculate the domain based on the locale

      return `https://www.teamazing.${domain}`; // Construct the link using the domain
    },
    getTeamazingHomepageLinkShort() {
      const locale = this.$root.$i18n.locale; // Get the current locale
      const domain = guessDomainWithLanguage(locale); // Calculate the domain based on the locale

      return `teamazing.${domain}`; // Construct the link using the domain
    },
    getTermsAndConditionsLink() {
      return buildRedirectLink(
        this.$i18n.locale,
        "https://www.teamazing.com/terms-of-service/"
      );
    },
  },
  methods: {
    activateGtm(w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    },
    addCookieDependendFeatures() {
      if (this.allowGoogleAnalytics) {
        this.addGoogleAnalytics();
      }

    },
    addGoogleAnalytics() {
      this.activateGtm(window, document, "script", "dataLayer", "GTM-KZH929S");
    },
    
    changeLanguage(locale) {
      this.$root.$i18n.locale = locale;
      if (this.$route.query.language !== locale) {
        let query = {
          language: locale,
        };
        if (this.$route.query.step !== undefined) {
          query["step"] = this.$route.query.step;
        }
        this.$router.replace({ query: query });
      }
    },
    checkCookieNotice() {
      const cookieString = localStorage.getItem(
        LOCAL_STORAGE_COOKIE_NOTICE_ACCEPTED
      );
      if (cookieString === undefined || cookieString === null) {
        this.showCookieNotice = true;
      } else {
        try {
          const cookieObject = JSON.parse(cookieString);
          this.allowGoogleAnalytics = cookieObject.allowGoogleAnalytics;
          this.allowLiveChat = cookieObject.allowLiveChat;
          this.addCookieDependendFeatures();
        } catch (error) {
          console.log(error);
          this.allowGoogleAnalytics = false;
          this.allowLiveChat = false;
          this.showCookieNotice = true;
        }
      }
    },
    async checkVersion() {
      let version = await fetchVersion();
      if (
        version.length === 1 &&
        this.versionAsNumber < parseInt(version[0].version)
      ) {
        window.location.reload();
      }
    },
    saveCookieSelection() {
      const cookieObject = {
        date: new Date(),
        allowGoogleAnalytics: this.allowGoogleAnalytics,
        allowLiveChat: this.allowLiveChat,
      };
      localStorage.setItem(
        LOCAL_STORAGE_COOKIE_NOTICE_ACCEPTED,
        JSON.stringify(cookieObject)
      );
      this.showCookieNotice = false;
    },
    goBack() {
      navigationBus.$emit("back");
    },
    goNext() {
      navigationBus.$emit("next");
    },
    onAgreeAllCookies() {
      this.allowGoogleAnalytics = true;
      this.allowLiveChat = true;
      this.saveCookieSelection();
      this.addCookieDependendFeatures();
    },
    onAgreeNoCookies() {
      this.allowGoogleAnalytics = false;
      this.allowLiveChat = false;
      this.saveCookieSelection();
      this.addCookieDependendFeatures();
    },
    onError(errorMessage) {
      if (errorMessage !== undefined && errorMessage !== null) {
        this.errorSnackbarText = errorMessage;
      } else {
        this.errorSnackbarText = this.$t("error-text");
      }
      this.showErrorSnackbar = true;
    },
    onEventModeChange(floatingButtonVisible) {
      this.floatingNavigation = {
        visible: floatingButtonVisible,
        continueDisabled: true,
        continueText: this.$t("continue"),
      };
    },
    onHideCookieDialog() {
      this.showCookieDialog = false;
    },
    onHideResetDialog() {
      this.showResetDialog = false;
    },
    onSaveCookieDialog(cookies) {
      this.allowGoogleAnalytics = cookies.allowGoogleAnalytics;
      this.allowLiveChat = cookies.allowLiveChat;
      this.saveCookieSelection();
      this.addCookieDependendFeatures();
    },
    onShowCookieDialog() {
      this.showCookieDialog = true;
    },
    onShowResetDialog() {
      this.showResetDialog = true;
    },
    onSelectionChange(continueDisabled) {
      this.floatingNavigation = {
        visible: true,
        continueDisabled: continueDisabled,
        continueText: this.$t("continue"),
      };
    },
    onUpgradesChange() {
      this.floatingNavigation = {
        visible: true,
        continueDisabled: false,
        continueText: this.$t("continue"),
      };
    },
    onLocationChange(continueDisabled) {
      this.floatingNavigation = {
        visible: true,
        continueDisabled: continueDisabled,
        continueText: this.$t("continue"),
      };
    },
    onDataChange(continueDisabled) {
      this.floatingNavigation = {
        visible: true,
        continueDisabled: continueDisabled,
        continueText: this.$t("data.send-offer"),
      };
    },
    openLinkBlank(link) {
      window.open(link, "_blank");
    },
    setLanguage() {
      if (this.$route.query.language !== undefined) {
        if (this.$route.query.language === LOCALE_GERMAN) {
          this.changeLanguage(LOCALE_GERMAN);
          return;
        }
        if (this.$route.query.language === LOCALE_ENGLISH) {
          this.changeLanguage(LOCALE_ENGLISH);
          return;
        }
      }
      this.changeLanguage(guessLocale());
    },
  },
};
</script>

<style scoped>
.a-no-decoration {
  text-decoration: none;
  color: white;
}
.a-no-decoration:hover {
  text-decoration: underline;
  color: var(--primary);
}
.app-footer {
  justify-content: center;
  width: 100%;
  color: var(--black4);
  background-color: white;
  font-size: small;
  padding: 0.125rem 0.5rem;
}
.app-footer a {
  color: var(--black4);
}
.app-footer div {
  align-items: center;
  justify-content: center;
}
.app-footer-container {
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: left;
}
.app-footer-container-mobile {
  justify-content: center;
}
.app-footer-container * {
  width: 100%;
}
.app-logo-image {
  display: none;
}
.app-header-help-button {
  display: none;
}
@media (min-width: 37.5rem) {
  .app-footer-container {
    display: flex;
  }
  .app-footer-container * {
    width: auto;
  }
  .app-footer-container-mobile {
    display: none;
  }
  .app-header-help-button {
    display: flex;
  }
  .app-header-mobile-menu {
    display: none;
  }
  .app-icon-image {
    display: none;
  }
  .app-logo-image {
    display: block;
  }
}
.header-language-flag {
  margin-left: 0.5rem !important;
}
</style>

<style>
@font-face {
  font-family: "Oswald";
  src: local("Oswald"),
    url(./fonts/Oswald/Oswald-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto/Roboto-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

:root {
  --primary: #ff0066;
  --secondary: #434343;
  --accent: #007ffd;
  --error: #b71c1c;
  --cultureGray: #f5f5f7;
  --black3: #6e6e73;
  --black4: #d2d2d7;
}

body {
  font-family: "Roboto";
}
h1,
h2,
h3 {
  font-family: "Oswald";
}
h1 {
  font-weight: bold;
}
h2 {
  font-weight: bold;
}
h3 {
  font-weight: 500;
}

.cookie-notice-snackbar-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.countries-container {
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  max-width: 122px;
}
.countries-container > div {
  margin-left: 1rem;
  margin-right: 1rem;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.hidden-mobile {
  display: none;
}
.item-cards-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  width: 100%;
  justify-content: center;
  padding-left: 0.25rem;
  padding-right: 0.35rem;
}
.position-absolute {
  position: absolute;
  z-index: 1;
}
@media (min-width: 30rem) {
  .item-cards-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 37.5rem) {
  .cookie-notice-snackbar-button-container {
    flex-direction: row;
  }
  .hidden-mobile {
    display: inline-block;
  }
}
@media (max-width: 839px) {
  h1 {
    font-size: x-large;
  }
}
@media (min-width: 64rem) {
  .item-cards-container {
    grid-template-columns: repeat(auto-fill, 12.5rem);
    max-width: calc(12.5rem * 5 + 2rem * 4);
  }
}
</style>
