<template>
  <div class="choice-card-outer-container">
    <v-card class="mx-auto choice-card-card-container">
      <v-img
        class="cursor-pointer"
        :src="imageUrl"
        height="240px"
        @click="onClick()"
        ><template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>

      <v-card-title class="cursor-pointer" @click="onClick()">
        {{ title }}
      </v-card-title>

      <v-card-subtitle
        class="cursor-pointer"
        @click="onClick()"
        v-html="description"
      >
      </v-card-subtitle>

      <v-card-actions v-if="moreContent && moreTitle">
        <v-btn color="primary lighten-2" text @click="show = !show">
          {{ moreTitle }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn icon @click="show = !show">
          <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition v-if="moreContent && moreTitle">
        <div v-show="show">
          <v-divider></v-divider>

          <v-card-text>
            <div v-for="content in moreContent" v-bind:key="content.title">
              <h3>{{ content.title }}</h3>
              <p v-if="content.text !== undefined">{{ content.text }}</p>
            </div>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ChoiceCard",
  props: ["imageUrl", "title", "description", "moreTitle", "moreContent"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.choice-card-card-container {
  height: 100%;
}
@media (min-width: 64rem) {
  .choice-card-card-container {
    max-width: 15rem;
  }
}
@media (min-width: 81.25rem) {
  .choice-card-card-container {
    max-width: 20rem;
  }
}
.choice-card-outer-container {
  border: 2px solid transparent;
  border-radius: 6px;
  height: 100%;
}
.choice-card-outer-container:hover {
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
}
</style>
