import axios from "axios";

export const REQUEST_FAILED = "REQUEST_FAILED";
// export const BASE_URL = "https://softwaredesire.at/other/teamazing/";
export const BASE_URL = "https://event.teamazing.com/server/";

const FETCH_ALL_WORKSHOPS = "fetch_all_workshops";
const FETCH_CHALLENGE_ITEM_SKILL = "fetch_challenge_item_skill";
const FETCH_CHALLENGE_ITEM_SKILLS = "fetch_challenge_item_skills";
const FETCH_CHALLENGE_ITEMS = "fetch_challenge_items";
const FETCH_CHALLENGE_ITEMS_WORKSHOPS = "fetch_challenge_items_workshops";
const FETCH_CITY_CHALLENGE_LOCATIONS = "fetch_city_challenge_locations";
const FETCH_COUNTRIES = "fetch_countries";
const FETCH_COUNTRY_CALLING_CODES = "fetch_country_calling_codes";
const FETCH_EVENT_CATEGORIES = "fetch_event_categories";
const FETCH_EVENT_CATEGORY = "fetch_event_category";
const FETCH_LOCATIONS = "fetch_locations";
const FETCH_LOCATION_COUNTRIES = "fetch_location_countries";
const FETCH_LOCATION_STATES = "fetch_location_states";
const FETCH_LOCATION_TYPES = "fetch_location_types";
const FETCH_LOCATIONS_AMOUNT = "fetch_locations_amount";
const FETCH_PRODUCTS = "fetch_products";
const FETCH_PRODUCTS_AS_UPGRADES = "fetch_products_as_upgrades";
const FETCH_THANK_YOU_LINK = "fetch_thank_you_link";
const FETCH_VERSION = "fetch_version";
const LOG_ACTIVITY = "log_activity";

function buildRoute(scriptName) {
  return `${BASE_URL}${scriptName}.php`;
}

async function get(scriptName, params) {
  const result = await axios.post(buildRoute(scriptName), params);
  if (result.status !== 200) {
    throw REQUEST_FAILED;
  }
  return result.data;
}

export async function fetchAllWorkshops(event_category_enum) {
  return await get(FETCH_ALL_WORKSHOPS, { enum: event_category_enum });
}

export async function fetchChallengeItemSkill() {
  return await get(FETCH_CHALLENGE_ITEM_SKILL);
}

export async function fetchChallengeItemSkills() {
  return await get(FETCH_CHALLENGE_ITEM_SKILLS);
}

export async function fetchChallengeItems(enums) {
  return await get(FETCH_CHALLENGE_ITEMS, { enums });
}

export async function fetchChallengeItemsWorkshops(enums) {
  return await get(FETCH_CHALLENGE_ITEMS_WORKSHOPS, { enums });
}

export async function fetchCityChallengeLocations(location_enum, country_abbr) {
  return await get(FETCH_CITY_CHALLENGE_LOCATIONS, {
    enum: location_enum,
    country_abbr,
  });
}

export async function fetchCountryCallingCodes() {
  return await get(FETCH_COUNTRY_CALLING_CODES);
}

export async function fetchCountries() {
  return await get(FETCH_COUNTRIES);
}

export async function fetchEventCategories(parent_id) {
  return await get(FETCH_EVENT_CATEGORIES, { parent_id });
}

export async function fetchEventCategory(event_category_enum) {
  return await get(FETCH_EVENT_CATEGORY, { enum: event_category_enum });
}

export async function fetchLocationCountries() {
  return await get(FETCH_LOCATION_COUNTRIES);
}

export async function fetchLocationStates(country_id) {
  return await get(FETCH_LOCATION_STATES, { country_id });
}

export async function fetchLocationTypes() {
  return await get(FETCH_LOCATION_TYPES);
}

export async function fetchLocations(
  location_enum,
  location_types_enums,
  country_id,
  state_id,
  limit,
  offset
) {
  return await get(FETCH_LOCATIONS, {
    enum: location_enum,
    location_types_enums,
    country_id,
    state_id,
    limit,
    offset,
  });
}

export async function fetchLocationsAmount(
  location_types_enums,
  country_id,
  state_id
) {
  return await get(FETCH_LOCATIONS_AMOUNT, {
    location_types_enums,
    country_id,
    state_id,
  });
}

export async function fetchProducts(eventCategoryEnum) {
  return await get(FETCH_PRODUCTS, { enum: eventCategoryEnum });
}

export async function fetchProductsAsUpgrades(enums) {
  return await get(FETCH_PRODUCTS_AS_UPGRADES, { enums });
}

export async function fetchVersion() {
  return await get(FETCH_VERSION);
}

export async function fetchThankYouLink(event_category_enum) {
  return await get(FETCH_THANK_YOU_LINK, { enum: event_category_enum });
}

export async function logActivity(guid, step) {
  return await get(LOG_ACTIVITY, { guid, step });
}
