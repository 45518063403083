<template>
  <div class="event-mode-selection-container">
    <div class="event-mode-selection-cards-column-container">
      <div
        class="flex-row"
        v-for="(item, index) in getNonSpecialItems"
        :key="index"
      >
        <div class="flex-column">
          <choice-card
            :image-url="item.thumbnail"
            :title="translate(item.title)"
            :description="translate(item.short_description)"
            v-on:click="onChoose(item)"
          />
          <div class="flex-grow"></div>
        </div>
        <div
          class="event-mode-selection-cards-column-or"
          v-if="index < getNonSpecialItems.length - 1"
        >
          <h2 v-if="getNonSpecialItems.length < 3">{{ $t("or") }}</h2>
        </div>
      </div>
    </div>
    <choice-card-long
      class="event-mode-selection-special-item-card"
      v-if="getSpecialItem !== undefined"
      :image-url="getSpecialItem.thumbnail"
      :title="translate(getSpecialItem.title)"
      :description="translate(getSpecialItem.short_description)"
      v-on:click="onChoose(getSpecialItem)"
    />
    <div class="event-mode-selection-cards-row-medium-container">
      <div v-for="(item, index) in items" :key="index">
        <choice-card-medium
          :image-url="item.thumbnail"
          :title="translate(item.title)"
          :description="translate(item.short_description)"
          v-on:click="onChoose(item)"
        />
        <div
          class="event-mode-selection-cards-row-or text-center"
          v-if="index < items.length - 1"
        >
          <h3 v-if="items.length < 3">{{ $t("or") }}</h3>
        </div>
      </div>
    </div>
    <div class="event-mode-selection-cards-row-small-container">
      <div v-for="(item, index) in items" :key="index">
        <choice-card-small
          :image-url="item.thumbnail"
          :title="translate(item.title)"
          :description="translate(item.short_description)"
          v-on:click="onChoose(item)"
          :large="hasSpecialItem && index === items.length - 1"
        />
        <div
          class="event-mode-selection-cards-row-or text-center"
          v-if="index < items.length - 1"
        >
          <h3 v-if="items.length < 3">{{ $t("or") }}</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChoiceCard from "../../gui-components/ChoiceCard.vue";
import ChoiceCardSmall from "../../gui-components/ChoiceCardSmall.vue";
import ChoiceCardMedium from "../../gui-components/ChoiceCardMedium.vue";
import ChoiceCardLong from "../../gui-components/ChoiceCardLong.vue";
import { translate } from "../../utils/helper";

export default {
  name: "EventModeSelection",
  components: {
    ChoiceCard,
    ChoiceCardMedium,
    ChoiceCardSmall,
    ChoiceCardLong,
  },
  props: ["items", "hasSpecialItem"],
  computed: {
    getNonSpecialItems() {
      if (this.hasSpecialItem) {
        return this.items.slice(0, this.items.length - 1);
      }
      return this.items;
    },
    getSpecialItem() {
      if (this.hasSpecialItem) {
        return this.items[this.items.length - 1];
      }
      return undefined;
    },
  },
  methods: {
    moreContent(item) {
      return translate(item.more_items, this);
    },
    onChoose(chosen) {
      this.$emit("choose", chosen);
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
.event-mode-selection-cards-column-container {
  justify-content: center;
  display: none;
}
.event-mode-selection-cards-row-medium-container {
  display: none;
}
.event-mode-selection-special-item-card {
  max-width: 72.75rem;
  display: none;
  margin-top: 3rem;
}
.event-mode-selection-cards-column-or {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
@media (min-width: 37.5rem) {
  .event-mode-selection-cards-row-small-container {
    display: none;
  }
  .event-mode-selection-cards-row-medium-container {
    display: inline-block;
  }
}
@media (min-width: 64rem) {
  .event-mode-selection-cards-column-container {
    display: flex;
  }
  .event-mode-selection-cards-row-medium-container {
    display: none;
  }
  .event-mode-selection-special-item-card {
    display: inline-block;
  }
}
@media (min-width: 87.5rem) {
  .event-mode-selection-cards-column-or {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.event-mode-selection-cards-column-or h2 {
  margin-top: calc(240px / 2 - 1.5em / 2);
}
.event-mode-selection-cards-row-or {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.event-mode-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
