<template>
  <div>
    <h1 class="selection-title">{{ getCurrentTitle }}</h1>
    <p v-if="isLocalFunEventMode() && !isEventMarketing()">
      {{ $t("selection.local-fun.text") }}
    </p>
    <p v-else-if="isOnlineFunEventMode()">
      {{ $t("selection.online-fun.text") }}
    </p>
    <div
      class="flex-row flex-center"
      v-if="
        allProducts.length > 0 && (isOnlineFunEventMode() || isContentFocus())
      "
    >
      <div class="selection-duration-slider-container">
        <v-checkbox
          class="selection-duration-checkbox"
          v-model="includeDesiredDuration"
          color="secondary"
          @change="adaptCurrentProducts()"
          :label="
            $t(
              isContentFocus()
                ? includeDesiredDuration
                  ? 'selection.content.duration-slider-title-on'
                  : 'selection.content.duration-slider-title-off'
                : includeDesiredDuration
                ? 'selection.online-fun.duration-slider-title-on'
                : 'selection.online-fun.duration-slider-title-off'
            )
          "
        ></v-checkbox>
        <v-slider
          v-if="includeDesiredDuration"
          class="selection-online-fun-slider"
          v-model="desiredDuration"
          tick-size="4"
          :step="STEP_SIZE_SLIDER"
          :min="isContentFocus() ? MIN_CONTENT_SLIDER : MIN_ONLINE_FUN_SLIDER"
          :max="isContentFocus() ? MAX_CONTENT_SLIDER : MAX_ONLINE_FUN_SLIDER"
          thumb-label="always"
          ticks="always"
          thumb-size="24"
          track-fill-color="primary"
          color="primary"
          thumb-color="primary"
          track-color="primary"
          @change="adaptCurrentProducts()"
        >
          <template v-slot:thumb-label="{ value }">
            {{ timeToLocaleString($i18n.locale, `${value}`) }}
          </template>
        </v-slider>
      </div>
    </div>
    <div
      class="selection-challenge-items-header"
      v-if="isLocalFunEventMode() && !isEventMarketing()"
    >
    <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <div           v-bind="attrs"
            v-on="on" class="selection-duration-slider-container  deactivated">
        <div class="selection-local-fun-slider-text">
          <v-icon class="selection-local-fun-slider-icon"
            >mdi-clock-start</v-icon
          >
          <div class="selection-local-fun-slider-title">
            {{ $t("selection.local-fun.duration-slider-title") }}
          </div>
        </div>
        <v-slider
          class="selection-local-fun-slider"
          v-model="selectChallengeItemCount"
          tick-size="4"
          :step="STEP_SIZE_SLIDER"
          :min="MIN_LOCAL_FUN_SLIDER"
          :max="MAX_LOCAL_FUN_SLIDER"
          readonly
          thumb-label="always"
          thumb-size="24"
          track-fill-color="black3"
          color="black3"
          ticks="always"
          thumb-color="black3"
          track-color="black3"
        >
          <template v-slot:thumb-label="{ value }">
            {{ timeToLocaleString($i18n.locale, `${value}`) }}
          </template>
        </v-slider>
      </div>
    </template>
    <span>{{ $t("selection.local-fun.duration-slider-tooltip") }}</span>
</v-tooltip>
      <v-btn
        class="selection-challenge-items-filter-button"
        text
        @click="isFilterOpen = !isFilterOpen"
      >
        {{ $t("selection.local-fun.filter") }}
        <v-icon right dark>
          {{ isFilterOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </div>
    <div class="flex-row flex-center" v-if="isEventMarketing()">
      <div class="selection-duration-slider-container">
        <div class="selection-event-marketing-slider-text">
          <v-icon class="selection-local-fun-slider-icon"
            >mdi-clock-start</v-icon
          >
          <div class="selection-local-fun-slider-title">
            {{ $t("selection.local-fun.duration-slider-title") }}
          </div>
        </div>
        <v-slider
          class="selection-online-fun-slider"
          v-model="desiredDuration"
          tick-size="4"
          :step="STEP_SIZE_SLIDER"
          :min="MIN_EVENT_MARKETING_SLIDER"
          :max="MAX_EVENT_MARKETING_SLIDER"
          thumb-label="always"
          ticks="always"
          thumb-size="24"
          track-fill-color="primary"
          color="primary"
          thumb-color="primary"
          track-color="primary"
        >
          <template v-slot:thumb-label="{ value }">
            {{ timeToLocaleString($i18n.locale, `${value}`) }}
          </template>
        </v-slider>
      </div>
    </div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <div v-else class="flex-column flex-center">
      <div
        v-if="isFilterOpen && isLocalFunEventMode() && !isEventMarketing()"
        class="selection-challenge-items-filter-container"
      >
        <v-select
          v-model="selectedChallengeItemSkills"
          color="secondary"
          :items="getChallengeItemSkills"
          deletable-chips
          small-chips
          item-text="name"
          item-value="enum"
          :label="$t('selection.local-fun.team-training-skills')"
          multiple
        ></v-select>
        <v-select
          v-model="selectedChallengeItemTypes"
          color="secondary"
          :items="getChallengeItemTypes"
          item-text="name"
          item-value="enum"
          deletable-chips
          small-chips
          :label="$t('selection.local-fun.task-type')"
          multiple
        ></v-select>
      </div>
      <div
        class="item-cards-container"
        v-if="
          getFilteredChallengeItems.length > 0 ||
          (isEventMarketing() && currentChallengeItems.length > 0)
        "
      >
        <div
          v-for="challengeItem in isEventMarketing()
            ? currentChallengeItems
            : getFilteredChallengeItems"
          :key="challengeItem.id"
        >
          <item-card
            :imageUrl="challengeItem.thumbnail"
            :link="challengeItem.detaillink"
            :tags="translate(challengeItem.skills)"
            :chipText="translate(challengeItem.type_name)"
            :chipIcon="challengeItem.type_icon"
            :title="translate(challengeItem.title)"
            v-on:click="onChallengeItem(challengeItem)"
            :disabled="selectChallengeItemCount >= MAX_LOCAL_FUN_SELECT_COUNT"
            :selected="challengeItem.selected"
            :onRequest="challengeItem.on_request"
          />
        </div>
      </div>
      <p
        v-if="
          isLocalFunEventMode() &&
          !isEventMarketing() &&
          getFilteredChallengeItems.length === 0
        "
      >
        {{ $t("selection.local-fun.no-results") }}
      </p>
      <div>
        <v-lazy>
          <v-tabs
            background-color="cultureGray"
            color="secondary"
            grow
            v-model="productThemeTab"
            v-if="productThemes.length > 1"
            class="selection-tabs-header"
          >
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab
              v-for="(theme, index) in productThemes"
              v-bind:key="index"
              :disabled="
                getThemedProducts(theme.theme_enum).length === 0 &&
                productThemeTab != index
              "
            >
              {{ translate(theme.theme_title) }}
            </v-tab>
          </v-tabs>
        </v-lazy>
        <v-tabs-items v-model="productThemeTab">
          <v-tab-item
            v-for="(theme, index) in productThemes"
            v-bind:key="index"
          >
            <div class="item-cards-container">
              <div
                v-for="product in getThemedProducts(theme.theme_enum)"
                :key="product.product.id"
              >
                <item-card
                  :imageUrl="product.product.thumbnail"
                  :link="product.product.detaillink"
                  :title="translate(product.product.title)"
                  chipIcon="mdi-clock-outline"
                  :chipText="
                    timesToShortDisplay(
                      $i18n.locale,
                      product.product.min_duration,
                      product.product.max_duration
                    )
                  "
                  v-on:click="onProduct(product.index, product.product)"
                  :selected="product.product.selected"
                  :disabled="selectProductCount >= MAX_ONLINE_FUN_SELECT_COUNT"
                />
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <p v-if="allProducts.length > 0 && currentProducts.length === 0">
        {{
          $t(
            isContentFocus()
              ? "selection.content.no-results"
              : "selection.online-fun.no-results"
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ItemCard from "../gui-components/ItemCard.vue";
import {
  EVENT_MODE_LOCAL_FUN,
  EVENT_MODE_LOCAL_CONTENT,
  EVENT_MODE_ONLINE_FUN,
  EVENT_MODE_ONLINE_CONTENT,
  PRODUCT_EVENT_MARKETING,
} from "../utils/constants";
import {
  timeToHours,
  timeToLocaleString,
  timesToShortDisplay,
  translate,
  hoursToMinutes,
  minutesToHours,
  hoursToDisplay,
  concatListToEnumeration,
} from "../utils/helper";
import {
  fetchChallengeItems,
  fetchChallengeItemSkill,
  fetchChallengeItemSkills,
  fetchProducts,
} from "../utils/server";
import { selectionBus } from "../main";

const STEP_SIZE_SLIDER = 0.5;
const MIN_LOCAL_FUN_SELECT_COUNT = 1;
const MAX_LOCAL_FUN_SELECT_COUNT = 4;
const MAX_ONLINE_FUN_SELECT_COUNT = 5;
const MIN_EVENT_MARKETING_COUNT = 0.5;
const MIN_LOCAL_FUN_SLIDER = 0;
const MAX_LOCAL_FUN_SLIDER = 4;
const MIN_CONTENT_SLIDER = 0.5;
const MAX_CONTENT_SLIDER = 12;
const MIN_ONLINE_FUN_SLIDER = 0.5;
const MAX_ONLINE_FUN_SLIDER = 5;
const MIN_EVENT_MARKETING_SLIDER = 0.5;
const MAX_EVENT_MARKETING_SLIDER = 8;
const LOCAL_FUN_DESIRED_DURATION_DEFAULT = 1.5;
const CONTENT_DESIRED_DURATION_DEFAULT = 3.5;
const EVENT_MARKETING_DESIRED_DURATION_DEFAULT = 2;

export default {
  name: "Selection",
  components: { ItemCard },
  props: ["status"],
  data() {
    return {
      productThemeTab: 0,
      productThemes: [],
      allProducts: [],
      challengeItemSkills: [],
      challengeItemTypes: [],
      currentProducts: [],
      currentChallengeItems: [],
      desiredDuration: LOCAL_FUN_DESIRED_DURATION_DEFAULT,
      includeDesiredDuration: false,
      isFilterOpen: false,
      loading: true,
      STEP_SIZE_SLIDER,
      MAX_LOCAL_FUN_SELECT_COUNT,
      MIN_LOCAL_FUN_SELECT_COUNT,
      MAX_ONLINE_FUN_SELECT_COUNT,
      MAX_EVENT_MARKETING_SLIDER,
      MIN_EVENT_MARKETING_SLIDER,
      MAX_LOCAL_FUN_SLIDER,
      MIN_LOCAL_FUN_SLIDER,
      MAX_CONTENT_SLIDER,
      MIN_CONTENT_SLIDER,
      MAX_ONLINE_FUN_SLIDER,
      MIN_ONLINE_FUN_SLIDER,
      selectChallengeItemCount: 0,
      selectProductCount: 0,
      selectedChallengeItems: new Map(),
      selectedChallengeItemSkills: [],
      selectedChallengeItemTypes: [],
      selectedProducts: new Map(),
    };
  },
  watch: {
    selectChallengeItemCount: function () {
      this.emitSelectionChange();
    },
    selectProductCount: function () {
      this.emitSelectionChange();
    },
  },
  mounted() {
    if (this.status.product_duration) {
      this.desiredDuration = minutesToHours(this.status.product_duration);
      this.includeDesiredDuration = true;
    } else if (this.isEventMarketing()) {
      this.desiredDuration = EVENT_MARKETING_DESIRED_DURATION_DEFAULT;
      this.includeDesiredDuration = true;
    } else if (this.isContentFocus()) {
      this.includeDesiredDuration = true;
    }
    if (this.isLocalFunEventMode()) {
      this.fetchChallengeItemsAndSkills();
    } else {
      const parsedProductDurationGetParameter =
        this.getParsedProductDurationGetParameter();
      if (parsedProductDurationGetParameter !== undefined) {
        if (this.isContentFocus() || this.isEventMarketing()) {
          if (
            parsedProductDurationGetParameter >= MIN_CONTENT_SLIDER &&
            parsedProductDurationGetParameter <= MAX_CONTENT_SLIDER
          ) {
            this.desiredDuration = parsedProductDurationGetParameter;
          }
        } else {
          if (
            parsedProductDurationGetParameter >= MIN_LOCAL_FUN_SLIDER &&
            parsedProductDurationGetParameter <= MAX_LOCAL_FUN_SLIDER
          ) {
            this.desiredDuration = parsedProductDurationGetParameter;
          }
        }
        this.includeDesiredDuration = true;
      } else if (this.isContentFocus()) {
        this.desiredDuration = CONTENT_DESIRED_DURATION_DEFAULT;
      } else if (this.isEventMarketing()) {
        this.desiredDuration = EVENT_MARKETING_DESIRED_DURATION_DEFAULT;
      } else {
        this.desiredDuration = LOCAL_FUN_DESIRED_DURATION_DEFAULT;
      }
      this.fetchProducts();
    }
  },
  created() {
    const self = this;
    selectionBus.$on("next", () => {
      self.onContinue();
    });
    this.emitSelectionChange();
  },
  computed: {
    getCurrentTitle() {
      if (this.isOnlineFunEventMode()) {
        return this.$t("selection.online-fun.title");
      }
      if (this.isLocalFunEventMode()) {
        return this.$t("selection.local-fun.title");
      }
      return this.$t("selection.content.title");
    },
    getFilteredChallengeItems() {
      let result = [];
      for (let i = 0; i < this.currentChallengeItems.length; ++i) {
        for (let j = 0; j < this.selectedChallengeItemSkills.length; ++j) {
          if (
            this.currentChallengeItems[i].skill_enums.includes(
              this.selectedChallengeItemSkills[j]
            ) &&
            this.selectedChallengeItemTypes.includes(
              this.currentChallengeItems[i].type_enum
            )
          ) {
            result.push(this.currentChallengeItems[i]);
            break;
          }
        }
      }
      return result;
    },
    getChallengeItemSkills() {
      let result = [];
      for (let i = 0; i < this.challengeItemSkills.length; ++i) {
        result.push({
          name: this.translate(this.challengeItemSkills[i].name),
          enum: this.challengeItemSkills[i].enum,
        });
      }
      return result;
    },
    getChallengeItemTypes() {
      let result = [];
      for (let i = 0; i < this.challengeItemTypes.length; ++i) {
        result.push({
          name: this.translate(this.challengeItemTypes[i].name),
          enum: this.challengeItemTypes[i].enum,
        });
      }
      return result;
    },
  },
  methods: {
    timeToLocaleString,
    timesToShortDisplay,
    adaptCurrentProducts() {
      let result = [];
      this.allProducts.forEach((product) => {
        if (
          !this.includeDesiredDuration ||
          ((product.min_duration === null ||
            timeToHours(product.min_duration) <= this.desiredDuration) &&
            (product.max_duration === null ||
              timeToHours(product.max_duration) >= this.desiredDuration))
        ) {
          result.push(product);
        }
      });
      this.currentProducts = result;
    },
    async appendChallengeItemSkills() {
      try {
        this.challengeItemSkills = await fetchChallengeItemSkills();
        let skillMap = new Map();
        this.selectedChallengeItemSkills = [];
        for (let i = 0; i < this.challengeItemSkills.length; ++i) {
          skillMap.set(
            this.challengeItemSkills[i].id,
            this.challengeItemSkills[i]
          );
          this.selectedChallengeItemSkills.push(
            this.challengeItemSkills[i].enum
          );
        }
        let challengeItemSkillMapping = await fetchChallengeItemSkill();
        for (let i = 0; i < this.currentChallengeItems.length; ++i) {
          this.currentChallengeItems[i]["skills_raw"] = [];
          this.currentChallengeItems[i]["skill_enums"] = [];
          for (let j = 0; j < challengeItemSkillMapping.length; ++j) {
            if (
              challengeItemSkillMapping[j].challenge_item_id ===
              this.currentChallengeItems[i].id
            ) {
              const currentSkill = skillMap.get(
                challengeItemSkillMapping[j].challenge_item_skill_id
              );
              this.currentChallengeItems[i]["skills_raw"].push(
                currentSkill.name
              );
              this.currentChallengeItems[i]["skill_enums"].push(
                currentSkill.enum
              );
            }
          }
        }
        for (let i = 0; i < this.currentChallengeItems.length; ++i) {
          let deList = [];
          let enList = [];
          for (
            let j = 0;
            j < this.currentChallengeItems[i].skills_raw.length;
            ++j
          ) {
            deList.push(
              JSON.parse(this.currentChallengeItems[i].skills_raw[j])["de"]
            );
            enList.push(
              JSON.parse(this.currentChallengeItems[i].skills_raw[j])["en"]
            );
          }
          this.currentChallengeItems[i]["skills"] = JSON.stringify({
            de: concatListToEnumeration(deList),
            en: concatListToEnumeration(enList),
          });
        }
      } catch (error) {
        this.onError(error);
      }
    },
    containsOnlyProductsWithoutMinMaxDuration() {
      if (this.isLocalFunEventMode()) {
        return false;
      }
      const products = Array.from(this.selectedProducts.values());
      for (let p = 0; p < products.length; p++) {
        if (
          products[p].min_duration !== null ||
          products[p].max_duration !== null
        ) {
          return false;
        }
      }
      return true;
    },
    emitSelectionChange() {
      this.$emit(
        "selectionChange",
        !(
          this.selectChallengeItemCount >= MIN_LOCAL_FUN_SELECT_COUNT ||
          this.selectProductCount > 0 ||
          (this.isEventMarketing() &&
            this.selectChallengeItemCount >= MIN_EVENT_MARKETING_COUNT)
        )
      );
    },
    emitSummary() {
      let deList = [];
      let enList = [];
      let enums = [];
      Array.from(
        this.isLocalFunEventMode()
          ? this.selectedChallengeItems.values()
          : this.selectedProducts.values()
      ).forEach((value) => {
        deList.push(JSON.parse(value.title)["de"]);
        enList.push(JSON.parse(value.title)["en"]);
        enums.push(value.enum);
      });
      let values = [
        {
          title: this.isLocalFunEventMode() ? "challenge_items" : "product",
          content: enums,
        },
      ];
      if (
        this.includeDesiredDuration &&
        !this.containsOnlyProductsWithoutMinMaxDuration()
      ) {
        values.push({
          title: "product_duration",
          content: hoursToMinutes(this.desiredDuration),
        });
      }
      const desiredDurationString =
        (!this.isLocalFunEventMode() &&
          this.includeDesiredDuration &&
          !this.containsOnlyProductsWithoutMinMaxDuration()) ||
        this.isEventMarketing()
          ? ` (${hoursToDisplay(
              this.$i18n.locale,
              this.desiredDuration.toString()
            )})`
          : "";
      this.$emit("summary", {
        key: "selection",
        title: "steps.selection",
        content: JSON.stringify({
          de: deList.join(", ") + desiredDurationString,
          en: enList.join(", ") + desiredDurationString,
        }),
        values,
      });
      this.$emit("next");
    },
    async fetchChallengeItemsAndSkills() {
      this.loading = true;
      await this.fetchChallengeItems();
      if (!this.isEventMarketing()) {
        await this.appendChallengeItemSkills();
      }
      this.loading = false;
    },
    async fetchChallengeItems() {
      try {
        let currentChallengeItems = await fetchChallengeItems(
          `'${this.status.product.join("','")}'`
        );
        currentChallengeItems.sort((a, b) => {
          return a.sort_order > b.sort_order
            ? -1
            : a.sort_order < b.sort_order
            ? 1
            : 0;
        });
        this.currentChallengeItems = currentChallengeItems;
        const getParameters = this.getGetParameters("challenge_items");
        this.selectedChallengeItemTypes = [];
        this.challengeItemTypes = [];
        for (let i = 0; i < this.currentChallengeItems.length; ++i) {
          const isSelected =
            (this.status.challenge_items &&
              this.status.challenge_items.length > 0 &&
              this.selectChallengeItemCount < MAX_LOCAL_FUN_SELECT_COUNT &&
              this.status.challenge_items.includes(
                this.currentChallengeItems[i].enum
              )) ||
            getParameters.includes(this.currentChallengeItems[i].enum);
          this.currentChallengeItems[i]["selected"] = isSelected;
          if (isSelected) {
            this.selectedChallengeItems.set(
              `${i}`,
              this.currentChallengeItems[i]
            );
            this.selectChallengeItemCount += 0.5;
          }
          if (
            !this.selectedChallengeItemTypes.includes(
              this.currentChallengeItems[i]["type_enum"]
            )
          ) {
            this.selectedChallengeItemTypes.push(
              this.currentChallengeItems[i]["type_enum"]
            );
            this.challengeItemTypes.push({
              name: this.currentChallengeItems[i]["type_name"],
              icon: this.currentChallengeItems[i]["type_icon"],
              enum: this.currentChallengeItems[i]["type_enum"],
            });
          }
        }
      } catch (error) {
        this.onError(error);
      }
    },
    async fetchProducts() {
      try {
        this.loading = true;
        let products = await fetchProducts(this.status.focus);
        products.sort((a, b) => {
          return a.sort_order > b.sort_order
            ? -1
            : a.sort_order < b.sort_order
            ? 1
            : 0;
        });
        const getParameters = this.getGetParameters("product");
        let themes = new Map();
        for (let i = 0; i < products.length; ++i) {
          if (!themes.has(products.theme_enum)) {
            themes.set(products[i].theme_enum, products[i]);
          }
          const isSelected =
            (this.status.product &&
              this.status.product.length > 0 &&
              this.selectProductCount < MAX_ONLINE_FUN_SELECT_COUNT &&
              this.status.product.includes(products[i].enum)) ||
            getParameters.includes(products[i].enum);
          products[i]["selected"] = isSelected;
          if (isSelected) {
            this.selectedProducts.set(`${i}`, products[i]);
            this.selectProductCount++;
          }
        }
        let productThemes = Array.from(themes.values());
        productThemes.sort((a, b) => {
          return a.theme_sort_order > b.theme_sort_order
            ? 1 // Reverse the comparison result for descending order
            : a.theme_sort_order < b.theme_sort_order
            ? -1 // Reverse the comparison result for descending order
            : 0;
        });
        this.productThemes = productThemes;
        this.allProducts = products;
        this.adaptCurrentProducts();
        this.loading = false;
        this.rerender++;
      } catch (error) {
        this.onError(error);
      }
    },
    getThemedProducts(themeEnum) {
      let result = [];
      for (let i = 0; i < this.currentProducts.length; ++i) {
        let product = this.currentProducts[i];
        if (product.theme_enum === themeEnum) {
          result.push({
            product,
            index: i,
          });
        }
      }
      return result;
    },
    isContentFocus() {
      return (
        this.status.focus === EVENT_MODE_ONLINE_CONTENT ||
        this.status.focus === EVENT_MODE_LOCAL_CONTENT
      );
    },
    isEventMarketing() {
      return (
        this.status.product &&
        this.status.product.length === 1 &&
        this.status.product[0] === PRODUCT_EVENT_MARKETING
      );
    },
    isLocalFunEventMode() {
      return this.status.focus === EVENT_MODE_LOCAL_FUN;
    },
    isOnlineFunEventMode() {
      return this.status.focus === EVENT_MODE_ONLINE_FUN;
    },
    onContinue() {
      this.emitSummary();
    },
    onChallengeItem(challengeItem) {
      for (let i = 0; i < this.currentChallengeItems.length; ++i) {
        if (this.currentChallengeItems[i].enum === challengeItem.enum) {
          this.currentChallengeItems[i].selected =
            !this.currentChallengeItems[i].selected;
          if (this.currentChallengeItems[i].selected) {
            this.selectedChallengeItems.set(`${i}`, challengeItem);
            this.selectChallengeItemCount += 0.5;
          } else {
            this.selectedChallengeItems.delete(`${i}`);
            this.selectChallengeItemCount -= 0.5;
          }
        }
      }
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    onProduct(index, product) {
      if (
        !this.currentProducts[index].selected &&
        !this.isOnlineFunEventMode()
      ) {
        this.resetProducts();
      }
      this.currentProducts[index].selected =
        !this.currentProducts[index].selected;
      if (this.currentProducts[index].selected) {
        this.selectedProducts.set(`${index}`, product);
        this.selectProductCount++;
      } else {
        this.selectedProducts.delete(`${index}`);
        this.selectProductCount--;
      }
      this.productsRerender++;
      if (
        !this.isOnlineFunEventMode() &&
        this.currentProducts[index].selected
      ) {
        this.onContinue();
      }
    },
    getGetParameters(key) {
      let result = [];
      if (Vue.prototype.$query !== undefined) {
        if (Vue.prototype.$query[key] !== undefined) {
          result = Vue.prototype.$query[key].split(",");
          Vue.prototype.$query[key] = undefined;
        }
      }
      return result;
    },
    getParsedProductDurationGetParameter() {
      let result = undefined;
      if (Vue.prototype.$query !== undefined) {
        if (Vue.prototype.$query.product_duration !== undefined) {
          let parameter = parseInt(Vue.prototype.$query.product_duration);
          if (!isNaN(parameter) && parameter % (STEP_SIZE_SLIDER * 60) === 0) {
            return minutesToHours(parameter);
          }
          Vue.prototype.$query.product_duration = undefined;
        }
      }
      return result;
    },
    resetProducts() {
      for (let i = 0; i < this.currentProducts.length; ++i) {
        this.selectedProducts.delete(`${i}`);
        this.currentProducts[i].selected = false;
      }
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
p {
  font-size: larger;
  text-align: center;
}
.selection-challenge-items-filter-button {
  margin-top: -1.5rem;
  margin-bottom: 1rem;
}
.selection-challenge-items-filter-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-width: calc(12.5rem * 4 + 2rem * 3);
  width: 100%;
}
.selection-challenge-items-filter-container div {
  padding-left: 1rem;
  padding-right: 1rem;
}
.selection-challenge-items-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.selection-duration-checkbox {
  margin-top: 0;
  margin-right: 1rem;
}
.selection-duration-slider-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 37.5rem;
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 0.5rem;
}
.selection-duration-slider-container.deactivated {
opacity: 0.3;
cursor: not-allowed;
}
.selection-event-marketing-slider-text {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -1.5rem;
}
.selection-online-fun-slider {
  margin-top: 1rem;
  width: 90%;
}
.selection-local-fun-slider {
  margin-top: 1rem;
  width: 90%;
}
.selection-local-fun-slider-icon {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
}
.selection-local-fun-slider-text {
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.selection-local-fun-slider-title {
  color: rgb(0, 0, 0, 0.6);
}
.selection-online-fun-slider >>> .v-slider {
  cursor: pointer;
}
.selection-online-fun-slider >>> .v-slider__tick {
  background-color: var(--primary);
}
.selection-local-fun-slider >>> .v-slider__tick {
  background-color: var(--black3);
}
.selection-tabs-header {
  margin-bottom: 2.5rem;
}
@media (min-width: 37.5rem) {
  .selection-challenge-items-filter-container {
    flex-direction: row;
  }
  .selection-challenge-items-filter-container div {
    width: 50%;
  }
}
@media (min-width: 52.5rem) {
  .selection-challenge-items-filter-button {
    margin-left: 1rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .selection-challenge-items-header {
    flex-direction: row;
  }
  .selection-duration-slider-container {
    flex-direction: row;
  }
  .selection-title {
    margin-bottom: 2rem;
  }
  .selection-online-fun-slider {
    margin-top: 0;
    width: auto;
  }
  .selection-local-fun-slider {
    padding-left: 1rem;
    padding-top: 1rem;
    margin-bottom: 1rem;
    width: auto;
  }
  .selection-local-fun-slider-text {
    margin-bottom: 0;
  }
}
</style>
