<template>
  <v-menu
    ref="timeMenu"
    v-model="timeMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="time"
        :label="label"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        @click:clear="deleteTime()"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="timeMenu"
      v-model="time"
      full-width
      @click:minute="saveTime()"
      format="24hr"
      :allowed-minutes="timeSteps"
      header-color="secondary"
    ></v-time-picker>
  </v-menu>
</template>

<script>
const TIME_STEP_SIZE = 15;

export default {
  name: "MyTimePicker",
  props: ["initialTime", "label"],
  data() {
    return {
      time: null,
      timeMenu: false,
      timeSteps: (m) => m % TIME_STEP_SIZE === 0,
    };
  },
  mounted() {
    this.time = this.initialTime ? this.initialTime : null;
  },
  methods: {
    deleteTime() {
      this.time = null;
      this.timeMenu = false;
      this.saveTime();
    },
    saveTime() {
      this.$refs.timeMenu.save(this.time);
      this.$emit("save", this.time);
    },
  },
};
</script>
