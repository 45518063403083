<template>
  <div class="choice-card-outer-container">
    <v-card @click="onClick()">
      <v-img
        :src="imageUrl"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)"
        :height="large ? '540px' : '200px'"
        width="100%"
        aspect-ratio="1"
        ><template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-card-title class="choice-card-small-title">{{ title }}</v-card-title
        ><v-card-subtitle
          class="cursor-pointer"
          @click="onClick()"
          v-html="description"
        ></v-card-subtitle>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ChoiceCardSmall",
  props: [
    "imageUrl",
    "title",
    "description",
    "moreTitle",
    "moreContent",
    "large",
  ],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.choice-card-outer-container {
  border: 2px solid transparent;
  border-radius: 6px;
}
.choice-card-outer-container:hover {
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
}
.choice-card-small-title {
  margin-top: 5rem;
}
</style>
