<template>
  <div>
    <v-lazy>
      <v-tabs
        background-color="cultureGray"
        color="secondary"
        grow
        v-model="tab"
        @change="checkLocation()"
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab>
          {{
            mobile
              ? $t("location.partner.title-short")
              : $t("location.partner.title")
          }}
        </v-tab>
        <v-tab>
          {{
            mobile ? $t("location.wish.title-short") : $t("location.wish.title")
          }}
        </v-tab>
      </v-tabs>
    </v-lazy>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <partner-location :status="status" v-on:save="save($event)" />
      </v-tab-item>
      <v-tab-item>
        <wish-location :status="status" v-on:save="save($event)" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { LOCATION_TYPE_WISH } from "../../utils/constants";
import PartnerLocation from "./PartnerLocation.vue";
import WishLocation from "./WishLocation.vue";

const PARTNER_INDEX = 0;
const WISH_INDEX = 1;

export default {
  components: { PartnerLocation, WishLocation },
  name: "PartnerWishLocation",
  props: ["status"],
  data() {
    return {
      tab: PARTNER_INDEX,
      wishResult: {},
      partnerResult: {},
    };
  },
  mounted() {
    this.tab = this.hasInitialWishLocationData() ? WISH_INDEX : PARTNER_INDEX;
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    checkLocation() {
      if (this.tab === WISH_INDEX) {
        this.$emit("save", this.wishResult);
      } else {
        this.$emit("save", this.partnerResult);
      }
    },
    hasInitialWishLocationData() {
      return (
        this.status.location &&
        this.status.location.type === LOCATION_TYPE_WISH &&
        this.status.location.data
      );
    },
    save(location) {
      if (this.tab === WISH_INDEX) {
        this.wishResult = location;
      } else {
        this.partnerResult = location;
      }
      this.$emit("save", location);
    },
  },
};
</script>

<style scoped></style>
