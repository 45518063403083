<template>
  <div>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        class="wish-location-progress-circle"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <v-form v-model="valid" v-else>
      <v-container>
        <v-row>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
              @change="save()"
              v-model="name"
              :label="$t('location.wish.name')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              @change="save()"
              v-model="address"
              :label="$t('location.wish.address')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              @change="save()"
              v-model="zipCode"
              :label="$t('location.wish.zip-code')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-text-field
              @change="save()"
              v-model="city"
              :label="$t('location.wish.city')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <v-select
              @change="save()"
              v-model="country"
              clearable
              :label="$t('location.wish.country')"
              :items="getCountries"
              item-text="name_translated"
              item-value="iso2"
              return-object
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import { LOCATION_TYPE_WISH } from "../../utils/constants";
import { guessCountry, translate } from "../../utils/helper";
import { fetchCountries } from "../../utils/server";

export default {
  name: "WishLocation",
  props: ["status"],
  data() {
    return {
      valid: false,
      name: "",
      address: "",
      zipCode: "",
      city: "",
      country: null,
      countries: [],
      loading: true,
    };
  },
  mounted() {
    this.checkInitialValues();
    this.fetchCountries();
  },
  computed: {
    getCountries() {
      let result = [];
      if (!this.loading) {
        for (let i = 0; i < this.countries.length; ++i) {
          let country = JSON.parse(JSON.stringify(this.countries[i]));
          country["name_translated"] = this.translate(country.name);
          result.push(country);
        }
      }
      result.sort((a, b) => {
        return a.name_translated < b.name_translated
          ? -1
          : a.name_translated > b.name_translated
          ? 1
          : 0;
      });
      return result;
    },
  },
  methods: {
    async fetchCountries() {
      try {
        this.loading = true;
        this.countries = await fetchCountries();
        let preselectedCountry = this.getPreselectedCountry();
        for (let i = 0; i < this.countries.length; ++i) {
          if (preselectedCountry === this.countries[i].iso2.trim()) {
            let country = JSON.parse(JSON.stringify(this.countries[i]));
            country["name_translated"] = this.translate(country.name);
            this.country = country;
            this.save();
            break;
          } else {
            continue;
          }
        }
        this.loading = false;
      } catch (error) {
        this.onError(error);
      }
    },
    isName() {
      return this.name.trim().length > 0;
    },
    isAddress() {
      return this.address.trim().length > 0;
    },
    isZipCode() {
      return this.zipCode.trim().length > 0;
    },
    isCity() {
      return this.city.trim().length > 0;
    },
    isCountry() {
      return this.country !== null;
    },
    getName() {
      if (this.isName()) {
        if (
          this.isAddress() ||
          this.isZipCode() ||
          this.isCity() ||
          this.isCountry()
        ) {
          return `${this.name.trim()}: `;
        }
        return this.name.trim();
      }
      return "";
    },
    getAddress() {
      if (this.isAddress()) {
        if (this.isZipCode() || this.isCity() || this.isCountry()) {
          return `${this.address.trim()}, `;
        }
        return this.address.trim();
      }
      return "";
    },
    getZipCode() {
      if (this.isZipCode()) {
        if (this.isCity() || this.isCountry()) {
          return `${this.zipCode.trim()} `;
        }
        return this.zipCode.trim();
      }
      return "";
    },
    getCity() {
      if (this.isCity()) {
        if (this.isCountry()) {
          return `${this.city.trim()} `;
        }
        return this.city.trim();
      }
      return "";
    },
    getCountry(locale) {
      if (this.isCountry()) {
        return JSON.parse(this.country.name)[locale].trim();
      }
      return "";
    },
    checkInitialValues() {
      if (this.hasInitialValues()) {
        this.name = this.getInitialStringValue(this.status.location.data.name);
        this.address = this.getInitialStringValue(
          this.status.location.data.address
        );
        this.zipCode = this.getInitialStringValue(
          this.status.location.data.zip
        );
        this.city = this.getInitialStringValue(this.status.location.data.city);
      }
    },
    getInitialStringValue(value) {
      return value ? value.trim() : "";
    },
    getPreselectedCountry() {
      if (this.hasInitialValues()) {
        return this.status.location.data.country;
      }
      return guessCountry();
    },
    hasInitialValues() {
      return (
        this.status.location &&
        this.status.location.type === LOCATION_TYPE_WISH &&
        this.status.location.data
      );
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    save() {
      let title =
        this.getName() + this.getAddress() + this.getZipCode() + this.getCity();

      let content = {
        de: title + this.getCountry("de"),
        en: title + this.getCountry("en"),
      };

      if (content["de"] === "" || content["en"] === "") {
        content = {
          de: this.$t("location.wish.default-name.de"),
          en: this.$t("location.wish.default-name.en"),
        };
      }

      let result = {
        content: JSON.stringify(content),
        value: {
          type: LOCATION_TYPE_WISH,
          data: {
            name: this.isName() ? this.name.trim() : undefined,
            address: this.isAddress() ? this.address.trim() : undefined,
            zip: this.isZipCode() ? this.zipCode.trim() : undefined,
            city: this.isCity() ? this.city.trim() : undefined,
            country: this.isCountry() ? this.country.iso2 : undefined,
          },
        },
      };

      this.$emit("save", result);
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
.wish-location-progress-circle {
  margin-top: 2rem;
}
</style>
