<template>
  <v-dialog v-model="show" max-width="600" class="reset-dialog-container">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("header.reset-dialog.title") }}
      </v-card-title>
      <v-card-text>
        <div v-html="$t('header.reset-dialog.text')"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="hide()" color="primary">
          {{ $t("cancel") }}
        </v-btn>
        <v-btn text @click="reset()">
          {{ $t("header.reset-dialog.button") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ResetDialog",
  props: ["show"],
  methods: {
    hide() {
      this.$emit("hide");
    },
    reset() {
      window.location.href = this.$route.path;
    },
  },
};
</script>

<style scoped>
.reset-dialog-container {
  z-index: 9999;
}
</style>
