<template>
  <div>
    <h1>{{ $t("upgrades.title") }}</h1>
    <p v-if="getAddOnAndGameIndices.length > 0">
      {{ $t("upgrades.proposals-title") }}
    </p>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <div v-else class="flex-column flex-center">
      <p
        v-if="
          getAddOnAndGameIndices.length === 0 && getWorkshopIndices.length === 0
        "
        class="upgrades-no-products"
      >
        {{ $t("upgrades.no-results") }}
      </p>
      <div v-else>
        <v-lazy>
          <v-tabs
            background-color="cultureGray"
            color="secondary"
            grow
            v-model="upgradesTab"
            class="upgrades-tabs-header"
            v-if="
              getAddOnAndGameIndices.length > 0 && getWorkshopIndices.length > 0
            "
          >
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab>{{ $t("upgrades.default-tab-title") }}</v-tab>
            <v-tab>{{ $t("upgrades.workshops-tab-title") }}</v-tab>
          </v-tabs>
        </v-lazy>
        <v-tabs-items v-model="upgradesTab">
          <v-tab-item>
            <div
              class="item-cards-container"
              v-if="getAddOnAndGameIndices.length > 0"
              :key="rerender"
            >
              <div
                v-for="index in getAddOnAndGameIndices"
                :key="currentProducts[index].id"
              >
                <item-card
                  :selected="currentProducts[index].selected"
                  :imageUrl="currentProducts[index].thumbnail"
                  :link="currentProducts[index].detaillink"
                  :title="translate(currentProducts[index].title)"
                  :chipIcon="
                    getChipIcon(currentProducts[index].product_category_enum)
                  "
                  :chipText="
                    translate(currentProducts[index].product_category_title)
                  "
                  v-on:click="onItemCard(index, currentProducts[index])"
                />
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div v-if="!loading && getWorkshopIndices.length > 0">
              <div class="flex-row flex-center">
                <div
                  class="item-cards-container"
                  v-if="getWorkshopIndices.length > 0"
                  :key="rerender"
                >
                  <div
                    v-for="index in getWorkshopIndices"
                    :key="currentProducts[index].id"
                  >
                    <item-card
                      :selected="currentProducts[index].selected"
                      :imageUrl="currentProducts[index].thumbnail"
                      :link="currentProducts[index].detaillink"
                      :title="translate(currentProducts[index].title)"
                      :chipIcon="
                        getChipIcon(
                          currentProducts[index].product_category_enum
                        )
                      "
                      :chipText="`${translate(
                        currentProducts[index].product_category_title
                      )} (${timeToShortDisplay(
                        $i18n.locale,
                        currentProducts[index].duration
                      )})`"
                      v-on:click="onItemCard(index, currentProducts[index])"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                !loading &&
                getAllWorkshopIndices.length > 0 &&
                !showAllWorkshops
              "
            >
              <br />
              <br />
              <br />
              <p
                class="text-center upgrades-show-all-workshops"
                @click="
                  showAllWorkshops = true;
                  rerender++;
                "
              >
                {{ $t("upgrades.workshops-see-all") }}
              </p>
            </div>
            <div
              v-if="
                !loading && getAllWorkshopIndices.length > 0 && showAllWorkshops
              "
            >
              <br />
              <br />
              <br />
              <p class="text-center">
                {{ $t("upgrades.workshops-all-title") }}
              </p>
              <div class="flex-row flex-center">
                <div
                  class="item-cards-container"
                  v-if="getAllWorkshopIndices.length > 0"
                  :key="rerender"
                >
                  <div
                    v-for="index in getAllWorkshopIndices"
                    :key="currentProducts[index].id"
                  >
                    <item-card
                      :selected="currentProducts[index].selected"
                      :imageUrl="currentProducts[index].thumbnail"
                      :link="currentProducts[index].detaillink"
                      :title="translate(currentProducts[index].title)"
                      :chipIcon="
                        getChipIcon(
                          currentProducts[index].product_category_enum
                        )
                      "
                      :chipText="`${translate(
                        currentProducts[index].product_category_title
                      )} (${timeToShortDisplay(
                        $i18n.locale,
                        currentProducts[index].duration
                      )})`"
                      v-on:click="onItemCard(index, currentProducts[index])"
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ItemCard from "../gui-components/ItemCard.vue";
import {
  EVENT_MODE_LOCAL_CONTENT,
  EVENT_MODE_ONLINE_CONTENT,
  PRODUCT_CATEGORY_ADD_ON,
  PRODUCT_CATEGORY_GAME,
  PRODUCT_CATEGORY_WORKSHOP,
  PRODUCT_CITY_CHALLENGE,
  PRODUCT_EVENT_MARKETING,
} from "../utils/constants";
import { translate, timeToShortDisplay } from "../utils/helper";
import {
  fetchAllWorkshops,
  fetchChallengeItemsWorkshops,
  fetchProductsAsUpgrades,
} from "../utils/server";
import { upgradesBus } from "../main";

export default {
  name: "Upgrades",
  components: { ItemCard },
  props: ["status"],
  data() {
    return {
      PRODUCT_CATEGORY_WORKSHOP,
      currentProducts: [],
      loading: true,
      selectedProducts: new Map(),
      rerender: 0,
      showAllWorkshops: false,
      upgradesTab: 0,
    };
  },
  mounted() {
    this.fetchUpgrades();
  },
  created() {
    const self = this;
    upgradesBus.$on("next", () => {
      self.onContinue();
    });
    this.emitUpgradesChange();
  },
  computed: {
    getAddOnAndGameIndices() {
      let result = [];
      for (let i = 0; i < this.currentProducts.length; ++i) {
        if (
          this.currentProducts[i].product_category_enum !==
            PRODUCT_CATEGORY_WORKSHOP &&
          this.currentProducts[i].product_category_enum !== undefined
        ) {
          result.push(i);
        }
      }
      return result;
    },
    getAllWorkshopIndices() {
      let result = [];
      for (let i = 0; i < this.currentProducts.length; ++i) {
        if (this.currentProducts[i].product_category_enum === undefined) {
          result.push(i);
        }
      }
      return result;
    },
    getWorkshopIndices() {
      let result = [];
      for (let i = 0; i < this.currentProducts.length; ++i) {
        if (
          this.currentProducts[i].product_category_enum ===
          PRODUCT_CATEGORY_WORKSHOP
        ) {
          result.push(i);
        }
      }
      return result;
    },
  },
  methods: {
    timeToShortDisplay,
    emitSummary() {
      let deList = [];
      let enList = [];
      let enums = [];
      Array.from(this.selectedProducts.values()).forEach((product) => {
        deList.push(JSON.parse(product.title)["de"]);
        enList.push(JSON.parse(product.title)["en"]);
        enums.push(product.enum);
      });
      const values = [
        {
          title: "upgrades",
          content: enums,
        },
      ];
      this.$emit("summary", {
        key: "upgrades",
        title: "steps.upgrades",
        content:
          this.selectedProducts.size > 0
            ? JSON.stringify({
                de: deList.join(", "),
                en: enList.join(", "),
              })
            : undefined,
        values,
      });
      this.$emit("next");
    },
    emitUpgradesChange() {
      this.$emit("upgradesChange");
    },
    getChipIcon(productCategoryEnum) {
      switch (productCategoryEnum) {
        case PRODUCT_CATEGORY_GAME:
          return "mdi-emoticon";
        case PRODUCT_CATEGORY_ADD_ON:
          return "mdi-puzzle-plus";
        default:
          return "mdi-school";
      }
    },
    getGetParameters() {
      let result = [];
      if (Vue.prototype.$query !== undefined) {
        if (Vue.prototype.$query.upgrades !== undefined) {
          result = Vue.prototype.$query.upgrades.split(",");
          Vue.prototype.$query.upgrades = undefined;
        }
      }
      return result;
    },
    async fetchUpgrades() {
      try {
        this.loading = true;
        this.currentProducts = await fetchProductsAsUpgrades(
          `'${this.status.product.join("','")}'`
        );
        if (
          this.status.focus !== EVENT_MODE_LOCAL_CONTENT &&
          this.status.focus !== EVENT_MODE_ONLINE_CONTENT &&
          !this.status.product.includes(PRODUCT_CITY_CHALLENGE) &&
          !this.status.product.includes(PRODUCT_EVENT_MARKETING)
        ) {
          let challengeItemWorkshops = [];
          if (
            this.status !== undefined &&
            this.status.challenge_items !== undefined &&
            this.status.challenge_items.length > 0
          ) {
            challengeItemWorkshops = await fetchChallengeItemsWorkshops(
              `'${this.status.challenge_items.join("','")}'`
            );
          }
          let newProducts = [];
          for (let w = 0; w < challengeItemWorkshops.length; ++w) {
            let add = true;
            for (let p = 0; p < this.currentProducts.length; ++p) {
              if (challengeItemWorkshops[w].id === this.currentProducts[p].id) {
                add = false;
              }
            }
            if (add) {
              newProducts.push(challengeItemWorkshops[w]);
            }
          }
          this.currentProducts.push(...newProducts);

          let allWorkshops = [];
          if (this.status !== undefined) {
            allWorkshops = await fetchAllWorkshops(this.status.eventtype);
          }
          newProducts = [];
          for (let w = 0; w < allWorkshops.length; ++w) {
            let add = true;
            allWorkshops[w].product_category_enum = undefined;
            allWorkshops[w].product_category_enum = undefined;
            for (let p = 0; p < this.currentProducts.length; ++p) {
              if (allWorkshops[w].id === this.currentProducts[p].id) {
                add = false;
              }
            }
            if (add) {
              newProducts.push(allWorkshops[w]);
            }
          }
          this.currentProducts.push(...newProducts);
        }

        const getParameters = this.getGetParameters();
        for (let i = 0; i < this.currentProducts.length; ++i) {
          if (this.status.upgrades && this.status.upgrades.length > 0) {
            this.currentProducts[i].selected = this.status.upgrades.includes(
              this.currentProducts[i].enum
            );
          } else if (getParameters.length > 0) {
            this.currentProducts[i].selected = getParameters.includes(
              this.currentProducts[i].enum
            );
          } else {
            this.currentProducts[i].selected =
              parseInt(this.currentProducts[i].preselected) > 0;
          }
          if (this.currentProducts[i].selected) {
            this.selectedProducts.set(`${i}`, this.currentProducts[i]);
          }
          if (
            this.currentProducts[i].selected &&
            this.currentProducts[i].product_category_enum === undefined
          ) {
            this.showAllWorkshops = true;
          }
        }
        this.loading = false;
      } catch (error) {
        this.onError(error);
      }
    },
    onItemCard(key, product) {
      if (!this.currentProducts[key].selected) {
        this.selectedProducts.set(`${key}`, product);
      } else {
        this.selectedProducts.delete(`${key}`);
      }
      this.currentProducts[key].selected = !this.currentProducts[key].selected;
      this.rerender++;
    },
    onContinue() {
      this.emitSummary();
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
p {
  font-size: larger;
  text-align: center;
  margin-bottom: 2rem;
}
.upgrades-no-products {
  text-align: center;
}
.upgrades-show-all-workshops {
  color: var(--accent);
  cursor: pointer;
  font-size: large;
}
.upgrades-tabs-header {
  margin-bottom: 2.5rem;
}
</style>
