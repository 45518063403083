<template>
  <v-menu
    ref="dateMenu"
    v-model="dateMenu"
    :close-on-content-click="true"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        @click:clear="deleteDate()"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      scrollable
      :first-day-of-week="1"
      color="primary"
      header-color="secondary"
      locale="de-de"
      :min="dateMin"
    >
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "MyDatePicker",
  props: ["initialDate", "label"],
  data() {
    return {
      date: null,
      dateMenu: false,
      dateMin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },
  watch: {
    date: function () {
      this.saveDate();
    },
  },
  mounted() {
    this.date = this.initialDate ? this.initialDate : null;
  },
  methods: {
    deleteDate() {
      this.date = null;
      this.dateMenu = false;
      this.$refs.dateMenu.save(this.date);
      this.saveDate();
    },
    saveDate() {
      this.$emit("save", this.date);
    },
  },
};
</script>

<style scoped></style>
