<template>
  <v-dialog
    v-model="show"
    persistent
    max-width="600"
    class="cookie-dialog-container"
  >
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("cookie-notice.title") }}
      </v-card-title>
      <v-card-text>
        {{ $t("cookie-notice.description") }}
        <!--<v-switch
          v-model="cookieNecessary"
          :label="$t('cookie-notice.necessary')"
          disabled
        ></v-switch>-->
        <v-switch
          v-model="cookieGoogleAnalytics"
          :label="$t('cookie-notice.google-analytics')"
        ></v-switch>
        <div v-html="$t('cookie-notice.google-analytics-text')"></div>
        <v-switch
          v-model="cookieLiveChat"
          :label="$t('cookie-notice.live-chat')"
        ></v-switch>
        <div v-html="$t('cookie-notice.live-chat-text')"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="cookie-dialog-button-container">
          <v-btn text @click="hide()">
            {{ $t("cookie-notice.cancel") }}
          </v-btn>
          <v-btn text @click="save()">
            {{ $t("cookie-notice.save") }}
          </v-btn>
          <v-btn text @click="acceptAll()" color="primary">
            {{ $t("cookie-notice.agree-all") }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CookieDialog",
  props: ["show"],
  data() {
    return {
      cookieGoogleAnalytics: false,
      cookieLiveChat: false,
    };
  },
  methods: {
    acceptAll() {
      this.submit(true, true);
    },
    hide() {
      this.$emit("hide");
    },
    save() {
      this.submit(this.cookieGoogleAnalytics, this.cookieLiveChat);
    },
    submit(cookieGoogleAnalytics, cookieLiveChat) {
      this.$emit("save", {
        allowGoogleAnalytics: cookieGoogleAnalytics,
        allowLiveChat: cookieLiveChat,
      });
      this.hide();
    },
  },
};
</script>

<style scoped>
.cookie-dialog-container {
  z-index: 9999;
}
.cookie-dialog-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (min-width: 37.5rem) {
  .cookie-dialog-button-container {
    flex-direction: row;
  }
}
</style>
