<template>
  <div>
    <h1>{{ $t("location.title") }}</h1>
    <p v-if="!isCityChallenge">{{ $t("location.text") }}</p>
    <partner-location
      v-if="isCityChallenge"
      :status="status"
      v-on:save="onSave($event)"
      v-on:error="onError($event)"
    />
    <partner-wish-location
      v-else
      :status="status"
      v-on:save="onSave($event)"
      v-on:error="onError($event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { PRODUCT_CITY_CHALLENGE } from "../utils/constants";
import PartnerLocation from "./location/PartnerLocation.vue";
import PartnerWishLocation from "./location/PartnerWishLocation.vue";
import { locationBus } from "../main";

export default {
  name: "Location",
  components: {
    PartnerLocation,
    PartnerWishLocation,
  },
  props: ["status"],
  data() {
    return {
      location: null,
    };
  },
  computed: {
    isCityChallenge() {
      return this.status.product.includes(PRODUCT_CITY_CHALLENGE);
    },
  },
  created() {
    const self = this;
    locationBus.$on("next", () => {
      self.onContinue();
    });
    this.emitLocationChange();
  },
  watch: {
    location: function () {
      this.emitLocationChange();
    },
  },
  methods: {
    emitLocationChange() {
      this.$emit("locationChange", this.location === null);
    },
    emitSummary() {
      const values = [
        {
          title: "location",
          content: this.location.value,
        },
      ];
      this.$emit("summary", {
        key: "location",
        title: "steps.location",
        content: this.location.content,
        values,
      });
      this.$emit("next");
    },
    onContinue() {
      Vue.prototype.$query.location = undefined;
      this.emitSummary();
    },
    onError(error) {
      this.$emit("error", error);
    },
    onSave(location) {
      this.location = location;
    },
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}
p {
  font-size: larger;
  text-align: center;
  margin-bottom: 2rem;
}
</style>
