<template>
  <div :class="getContainerClass" @click="onClick()">
    <img :src="asset" :height="getFlagSize" :width="getFlagSize * 1.5" />
  </div>
</template>

<script>
export default {
  name: "CountryButton",
  props: ["asset", "country", "selectedCountry", "disabled", "small"],
  computed: {
    getContainerClass() {
      let result = "";
      if (!this.disabled) {
        result = "cursor-pointer";
      }
      if (!this.isSelectedCountry()) {
        result += " country-unselected";
      }
      return result;
    },
    getFlagSize() {
      if (this.small) {
        return this.isSelectedCountry() ? "30" : "20";
      }
      return this.isSelectedCountry() ? "36" : "24";
    },
  },
  methods: {
    isSelectedCountry() {
      return this.country === this.selectedCountry;
    },
    onClick() {
      if (!this.disabled) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped>
.country-unselected {
  opacity: 0.6;
}
</style>
