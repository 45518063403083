var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("upgrades.title")))]),(_vm.getAddOnAndGameIndices.length > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("upgrades.proposals-title"))+" ")]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"secondary"}})],1):_c('div',{staticClass:"flex-column flex-center"},[(
        _vm.getAddOnAndGameIndices.length === 0 && _vm.getWorkshopIndices.length === 0
      )?_c('p',{staticClass:"upgrades-no-products"},[_vm._v(" "+_vm._s(_vm.$t("upgrades.no-results"))+" ")]):_c('div',[_c('v-lazy',[(
            _vm.getAddOnAndGameIndices.length > 0 && _vm.getWorkshopIndices.length > 0
          )?_c('v-tabs',{staticClass:"upgrades-tabs-header",attrs:{"background-color":"cultureGray","color":"secondary","grow":""},model:{value:(_vm.upgradesTab),callback:function ($$v) {_vm.upgradesTab=$$v},expression:"upgradesTab"}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_c('v-tab',[_vm._v(_vm._s(_vm.$t("upgrades.default-tab-title")))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t("upgrades.workshops-tab-title")))])],1):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.upgradesTab),callback:function ($$v) {_vm.upgradesTab=$$v},expression:"upgradesTab"}},[_c('v-tab-item',[(_vm.getAddOnAndGameIndices.length > 0)?_c('div',{key:_vm.rerender,staticClass:"item-cards-container"},_vm._l((_vm.getAddOnAndGameIndices),function(index){return _c('div',{key:_vm.currentProducts[index].id},[_c('item-card',{attrs:{"selected":_vm.currentProducts[index].selected,"imageUrl":_vm.currentProducts[index].thumbnail,"link":_vm.currentProducts[index].detaillink,"title":_vm.translate(_vm.currentProducts[index].title),"chipIcon":_vm.getChipIcon(_vm.currentProducts[index].product_category_enum),"chipText":_vm.translate(_vm.currentProducts[index].product_category_title)},on:{"click":function($event){return _vm.onItemCard(index, _vm.currentProducts[index])}}})],1)}),0):_vm._e()]),_c('v-tab-item',[(!_vm.loading && _vm.getWorkshopIndices.length > 0)?_c('div',[_c('div',{staticClass:"flex-row flex-center"},[(_vm.getWorkshopIndices.length > 0)?_c('div',{key:_vm.rerender,staticClass:"item-cards-container"},_vm._l((_vm.getWorkshopIndices),function(index){return _c('div',{key:_vm.currentProducts[index].id},[_c('item-card',{attrs:{"selected":_vm.currentProducts[index].selected,"imageUrl":_vm.currentProducts[index].thumbnail,"link":_vm.currentProducts[index].detaillink,"title":_vm.translate(_vm.currentProducts[index].title),"chipIcon":_vm.getChipIcon(
                        _vm.currentProducts[index].product_category_enum
                      ),"chipText":`${_vm.translate(
                      _vm.currentProducts[index].product_category_title
                    )} (${_vm.timeToShortDisplay(
                      _vm.$i18n.locale,
                      _vm.currentProducts[index].duration
                    )})`},on:{"click":function($event){return _vm.onItemCard(index, _vm.currentProducts[index])}}})],1)}),0):_vm._e()])]):_vm._e(),(
              !_vm.loading &&
              _vm.getAllWorkshopIndices.length > 0 &&
              !_vm.showAllWorkshops
            )?_c('div',[_c('br'),_c('br'),_c('br'),_c('p',{staticClass:"text-center upgrades-show-all-workshops",on:{"click":function($event){_vm.showAllWorkshops = true;
                _vm.rerender++;}}},[_vm._v(" "+_vm._s(_vm.$t("upgrades.workshops-see-all"))+" ")])]):_vm._e(),(
              !_vm.loading && _vm.getAllWorkshopIndices.length > 0 && _vm.showAllWorkshops
            )?_c('div',[_c('br'),_c('br'),_c('br'),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("upgrades.workshops-all-title"))+" ")]),_c('div',{staticClass:"flex-row flex-center"},[(_vm.getAllWorkshopIndices.length > 0)?_c('div',{key:_vm.rerender,staticClass:"item-cards-container"},_vm._l((_vm.getAllWorkshopIndices),function(index){return _c('div',{key:_vm.currentProducts[index].id},[_c('item-card',{attrs:{"selected":_vm.currentProducts[index].selected,"imageUrl":_vm.currentProducts[index].thumbnail,"link":_vm.currentProducts[index].detaillink,"title":_vm.translate(_vm.currentProducts[index].title),"chipIcon":_vm.getChipIcon(
                        _vm.currentProducts[index].product_category_enum
                      ),"chipText":`${_vm.translate(
                      _vm.currentProducts[index].product_category_title
                    )} (${_vm.timeToShortDisplay(
                      _vm.$i18n.locale,
                      _vm.currentProducts[index].duration
                    )})`},on:{"click":function($event){return _vm.onItemCard(index, _vm.currentProducts[index])}}})],1)}),0):_vm._e()])]):_vm._e()])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }