<template>
  <div class="buttons-container flex-row">
    <v-card outlined>
      <v-card-text class="buttons-container-buttons">
        <v-btn text @click="onBack()" v-if="!backHidden">{{
          $t("back")
        }}</v-btn>
        <v-btn
          :disabled="continueDisabled"
          color="unifyAzure"
          :dark="!continueDisabled"
          @click="onContinue()"
          >{{ continueText }}</v-btn
        >
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "FloatingNavigation",
  props: ["continueText", "continueDisabled", "backHidden"],
  methods: {
    onBack() {
      this.$emit("back");
    },
    onContinue() {
      this.$emit("continue");
    },
  },
};
</script>

<style scoped>
.buttons-container {
  justify-content: flex-end;
  position: sticky;
  bottom: 1rem;
  padding-right: 1rem;
  z-index: 2;
  width: 100%;
}
.buttons-container-buttons {
  padding: 0.5rem;
}
@media (min-width: 37.5rem) {
  .buttons-container-buttons {
    padding: 1rem;
  }
}
</style>
