<template>
  <div class="choice-card-outer-container">
    <v-card @click="onClick()">
      <div class="d-flex flex-no-wrap justify-space-between">
        <div>
          <v-card-title class="text-h5 cursor-pointer" @click="onClick()">{{
            title
          }}</v-card-title>

          <v-card-subtitle
            class="cursor-pointer"
            @click="onClick()"
            v-html="description"
          ></v-card-subtitle>
        </div>

        <v-avatar class="ma-3" size="140" tile>
          <v-img class="cursor-pointer" :src="imageUrl"
            ><template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-row> </template
          ></v-img>
        </v-avatar>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "ChoiceCardMedium",
  props: ["imageUrl", "title", "description", "moreTitle", "moreContent"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.choice-card-outer-container {
  border: 2px solid transparent;
  border-radius: 6px;
}
.choice-card-outer-container:hover {
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
}
</style>
