<template>
  <div :class="getItemCardOuterContainerClass">
    <v-card
      @click="onClick()"
      class="item-card-container-mobile"
      :disabled="!selected && !selectedIntern && disabled"
    >
      <div
        v-if="selected || selectedIntern"
        class="item-card-overlay position-absolute cursor-pointer"
      ></div>
      <v-img
        v-if="selected || selectedIntern"
        class="item-card-check cursor-pointer"
        :src="require('./../assets/icon_check_circle.svg')"
        width="40"
        height="40"
      />
      <v-icon
        :color="checkColor"
        v-if="selected || selectedIntern"
        x-large
        class="item-card-check cursor-pointer"
        >mdi-check-circle</v-icon
      >
      <v-img
        :src="
          imageUrl ? imageUrl : require('./../assets/card_placeholder.jpeg')
        "
        class="white--text align-end item-card-mobile-image"
        :gradient="imageUrl ? 'to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)' : ''"
        height="200px"
        aspect-ratio="1"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-card-subtitle class="cursor-pointer">
          <div class="item-card-mobile-subtitle-container">
            <div>
              <div :class="getItemCardMobileSubtitleClass">
                {{ title }}
              </div>
              <div v-if="tags" :class="getItemCardMobileTagsClass">
                {{ tags }}
              </div>
              <v-chip
                :class="getItemCardMobileChipClass"
                v-if="chipText !== undefined && chipText !== null"
                :color="imageUrl ? 'cultureGray' : 'secondary'"
                x-small
              >
                <v-icon left x-small :color="imageUrl ? 'secondary' : 'white'">
                  {{ chipIcon }}
                </v-icon>
                <div>
                  {{ chipText }}
                </div> </v-chip
              ><br />
              <v-chip
                :class="getItemCardMobileChipClass"
                v-if="onRequest === '1'"
                :color="imageUrl ? 'cultureGray' : 'secondary'"
                x-small
              >
                <v-icon left x-small :color="imageUrl ? 'secondary' : 'white'">
                  mdi-information-outline
                </v-icon>
                <div>
                  {{ $t("selection.local-fun.on-request") }}
                </div>
              </v-chip>
            </div>
            <v-btn
              icon
              v-if="link && !selected && !selectedIntern"
              :href="getRedirectLink(link)"
              target="_blank"
              class="caption"
              v-on:click.stop
              :class="getItemCardMobileInfoButtonClass"
            >
              <v-icon :color="imageUrl ? 'white' : 'black'"
                >mdi-information-outline</v-icon
              >
            </v-btn>
          </div>
        </v-card-subtitle>
      </v-img>
    </v-card>
    <v-card
      class="mx-auto item-card-card-container item-card-container-not-mobile"
      max-width="200"
      @click="onClick()"
      :disabled="!selected && !selectedIntern && disabled"
    >
      <div
        v-if="selected || selectedIntern"
        class="item-card-overlay position-absolute cursor-pointer"
      ></div>
      <v-icon
        :color="checkColor"
        v-if="selected || selectedIntern"
        x-large
        class="item-card-check cursor-pointer"
        >mdi-check-circle</v-icon
      >
      <v-img
        v-if="imageUrl"
        class="cursor-pointer item-card-image"
        :src="imageUrl"
        height="200"
        max-height="200"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>
      <v-img
        v-else
        class="cursor-pointer item-card-image"
        :src="require('./../assets/card_placeholder.jpeg')"
        height="200"
        max-height="200"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </v-row> </template
      ></v-img>

      <v-card-title class="cursor-pointer body-2 item-card-title">
        {{ title }}
      </v-card-title>

      <v-card-subtitle :class="getItemCardSubtitleClass">
        <div v-if="tags">
          {{ tags }}
        </div>
        <v-chip
          v-if="chipText !== undefined && chipText !== null"
          :color="chipColor"
          x-small
        >
          <v-icon left x-small color="secondary"> {{ chipIcon }} </v-icon>
          <div class="item-card-chip-text">{{ chipText }}</div>
        </v-chip>
        <v-chip v-if="onRequest === '1'" color="#fff7b4" x-small>
          <v-icon left x-small color="secondary">
            mdi-information-outline
          </v-icon>
          <div class="item-card-chip-text">
            {{ $t("selection.local-fun.on-request") }}
          </div>
        </v-chip>
      </v-card-subtitle>
      <v-card-actions class="item-card-actions-container" v-if="link">
        <v-dialog v-model="dialog" max-width="800">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              text
              class="caption"
              v-on="on"
              v-bind="attrs"
            >
              <span class="mr-2">{{ $t("details") }}</span>
              <v-icon small color="primary">mdi-open-in-new</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
   
              <iframe
                width="100%"
                height="500px"
                frameborder="0"
                :src="getRedirectLink(link)" 
              ></iframe>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="dialog = false">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { buildRedirectLink } from "../utils/helper";
export default {
  name: "ItemCard",
  props: [
    "selected",
    "imageUrl",
    "title",
    "tags",
    "link",
    "disabled",
    "chipText",
    "chipIcon",
    "onRequest",
  ],
  data() {
    return {
      selectedIntern: false,
      checkColor: this.$vuetify.theme.themes.light.accent,
      dialog: false,
    };
  },
  computed: {
    getItemCardOuterContainerClass() {
      let result = "item-card-outer-container";
      if (!this.disabled || this.selected) {
        result += " item-card-outer-container-border";
      }
      return result;
    },
    getItemCardMobileChipClass() {
      if (this.imageUrl) {
        return "item-card-mobile-chip-on-image";
      }
      return "";
    },
    getItemCardMobileInfoButtonClass() {
      if (this.imageUrl) {
        return "item-card-mobile-info-button-on-image";
      }
      return "";
    },
    getItemCardMobileSubtitleClass() {
      let result = "item-card-mobile-subtitle";
      if (this.imageUrl) {
        result += " item-card-mobile-text-on-image";
      } else {
        result += " item-card-mobile-text-on-placeholder";
      }
      return result;
    },
    getItemCardMobileTagsClass() {
      let result = "item-card-mobile-tags";
      if (this.imageUrl) {
        result += " item-card-mobile-text-on-image";
      } else {
        result += " item-card-mobile-text-on-placeholder";
      }
      return result;
    },
    getItemCardSubtitleClass() {
      let result = "cursor-pointer caption";
      if (this.link) {
        result += " item-card-subtitle";
      }
      return result;
    },
    chipColor() {
    if (['Calm & Focused', 'Ruhig & Konzentriert'].includes(this.chipText)) {
      return '#eafbff';
    }
    if (['Sporty & Active', 'Sportlich & Aktiv'].includes(this.chipText)) {
      return '#eaffee';
    }
    return 'cultureGray';
    },
  },
  methods: {
    getRedirectLink(link) {
      return buildRedirectLink(this.$i18n.locale, link)+"&eventdesigner=1";
    },
    onClick() {
      if (this.selected === undefined) {
        this.selectedIntern = !this.selectedIntern;
        this.$emit("click", this.selectedIntern);
      } else {
        this.$emit("click", this.selected);
      }
    },
  },
};
</script>

<style scoped>
.item-card-actions-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
.item-card-card-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.item-card-check {
  bottom: 0.5rem;
  right: 0.5rem;
  position: absolute;
  z-index: 1;
}
.item-card-chip-text {
  color: var(--secondary);
}
.item-card-image {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.item-card-mobile-chip-on-image {
  opacity: 0.6;
}
.item-card-mobile-image {
  border-radius: 0.25rem;
}
.item-card-mobile-info-button-on-image {
  opacity: 0.8;
}
.item-card-mobile-subtitle {
  font-size: large;
  font-weight: 500;
  word-break: break-word;
}
.item-card-mobile-subtitle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.item-card-mobile-tags {
  font-size: small;
}
.item-card-mobile-text-on-image {
  color: white;
  opacity: 0.8;
}
.item-card-mobile-text-on-placeholder {
  color: black;
}
.item-card-outer-container {
  border: 2px solid transparent;
  border-radius: 6px;
  height: 100%;
}
.item-card-outer-container-border {
  width: calc(100% + 0.25rem);
}
.item-card-outer-container-border:hover {
  border: 2px solid var(--secondary);
  background-color: var(--secondary);
}
.item-card-container-not-mobile {
  display: none;
}
.item-card-overlay {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  border-radius: 0.25rem;
}
.item-card-subtitle {
  padding-bottom: 0 !important;
}
.item-card-title {
  text-align: left !important;
  word-break: normal;
  font-weight: 500;
}
@media (min-width: 64rem) {
  .item-card-container-mobile {
    display: none;
  }
  .item-card-container-not-mobile {
    display: flex;
  }
}
</style>
