<template>
  <div>
    <div class="text-center" v-if="loading > 0">
      <v-progress-circular
        class="partner-location-progress-circle"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-row class="filter-container flex-row">
        <v-col cols="12" v-if="isCityChallenge">
          <div class="countries-container flex-row">
            <country-button
              :asset="require('./../../assets/austrian-flag.svg')"
              :country="COUNTRY_ABBR_AUSTRIA"
              :selectedCountry="cityChallengeCountry"
              v-on:click="onCityChallengeCountry(COUNTRY_ABBR_AUSTRIA)"
            />
            <country-button
              :asset="require('./../../assets/german-flag.svg')"
              :country="COUNTRY_ABBR_GERMANY"
              :selectedCountry="cityChallengeCountry"
              v-on:click="onCityChallengeCountry(COUNTRY_ABBR_GERMANY)"
            />
          </div>
        </v-col>
        <v-col v-else cols="12" xs="6" sm="6" md="6" lg="4" xl="4">
          <v-select
            @change="onCountry()"
            v-model="country"
            :label="$t('location.partner.country')"
            :items="countries"
            item-value="id"
            return-object
            clearable
          >
            <template slot="selection" slot-scope="data">
              {{ translate(data.item.name) }}
            </template>
            <template slot="item" slot-scope="data">
              {{ translate(data.item.name) }}
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="!isCityChallenge"
          cols="12"
          xs="6"
          sm="6"
          md="6"
          lg="4"
          xl="4"
        >
          <v-select
            @change="onState()"
            :disabled="states.length === 0"
            v-model="state"
            :label="$t('location.partner.state')"
            :items="states"
            item-value="id"
            return-object
            clearable
          >
            <template slot="selection" slot-scope="data">
              {{ translate(data.item.name) }}
            </template>
            <template slot="item" slot-scope="data">
              {{ translate(data.item.name) }}
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="!isCityChallenge"
          cols="12"
          xs="6"
          sm="6"
          md="6"
          lg="4"
          xl="4"
        >
          <div class="flex-row flex-center">
            <v-select
              color="secondary"
              v-model="selectedLocationTypes"
              :items="locationTypes"
              item-text="name"
              item-value="enum"
              :label="$t('location.partner.hotel-type')"
              multiple
              persistent-hint
              @blur="fetchLocationDetails()"
            ></v-select>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="location-type-description-icon"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span v-html="getLocationTypesDescription"> </span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col
          v-if="!isCityChallenge"
          cols="12"
          xs="6"
          sm="6"
          md="6"
          lg="0"
          xl="0"
        >
        </v-col>
      </v-row>
      <div class="flex-row flex-center" v-if="currentLocations.length > 0">
        <div class="item-cards-container">
          <div v-for="(location, index) in currentLocations" :key="location.id">
            <item-card
              :imageUrl="location.thumbnail"
              :link="isCityChallenge ? undefined : location.detaillink"
              :title="location.title"
              :tags="
                location.state_name ? translate(location.state_name) : undefined
              "
              :chipIcon="
                isCityChallenge
                  ? undefined
                  : getChipIcon(location.location_type_id)
              "
              :chipText="
                isCityChallenge
                  ? undefined
                  : translate(location.location_types_title)
              "
              :selected="location.selected"
              v-on:click="changeSelection(index, location)"
            />
          </div>
        </div>
      </div>
      <v-pagination
        color="unifyAzure"
        v-if="getLocationsPaginationLength > 1 && currentLocations.length > 0"
        v-model="currentPage"
        class="my-4 partner-location-pagination"
        :length="getLocationsPaginationLength"
        @input="changeLocations()"
      ></v-pagination>
      <div v-if="currentLocations.length === 0" class="text-center">
        {{ $t("location.partner.filter-no-results") }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CountryButton from "../../gui-components/CountryButton.vue";
import ItemCard from "../../gui-components/ItemCard.vue";
import { guessCountry, translate } from "../../utils/helper";
import {
  fetchCityChallengeLocations,
  fetchLocationCountries,
  fetchLocations,
  fetchLocationsAmount,
  fetchLocationStates,
  fetchLocationTypes,
} from "../../utils/server";
import {
  COUNTRY_ABBR_AUSTRIA,
  COUNTRY_ABBR_GERMANY,
  HOTEL_CATEGORY_BUSINESS_ID,
  HOTEL_CATEGORY_CITY_ID,
  LOCATION_TYPE_PARTNER,
  PRODUCT_CITY_CHALLENGE,
} from "./../../utils/constants";

const HOTEL_TYPE_BUSINESS = "business";
const HOTEL_TYPE_CASUAL = "casual";
const HOTEL_TYPE_CITY = "city";
const LOCATIONS_REQUEST_LIMIT = 20;

export default {
  name: "PartnerLocation",
  components: {
    CountryButton,
    ItemCard,
  },
  props: ["status"],
  data() {
    return {
      COUNTRY_ABBR_AUSTRIA,
      COUNTRY_ABBR_GERMANY,
      HOTEL_TYPE_BUSINESS,
      HOTEL_TYPE_CASUAL,
      HOTEL_TYPE_CITY,
      allLocations: [],
      cityChallengeCountry: COUNTRY_ABBR_GERMANY,
      country: null,
      countries: [],
      currentLocations: [],
      currentPage: 1,
      isCityChallenge: false,
      loading: 0,
      locationsAmount: 0,
      locationTypes: [],
      state: null,
      states: [],
      selectedLocationTypes: [],
      selectedLocation: null,
    };
  },
  mounted() {
    this.isCityChallenge = this.status.product.includes(PRODUCT_CITY_CHALLENGE);
    if (this.isCityChallenge) {
      // TODO: remove
      this.cityChallengeCountry = guessCountry();
      this.fetchCityChallengeLocations();
    } else {
      this.fetchAll();
    }
  },
  computed: {
    getLocationTypesDescription() {
      let locationTypesDescription = "";
      for (let i = 0; i < this.locationTypes.length; ++i) {
        const title = this.translate(this.locationTypes[i].title);
        const description = this.translate(
          this.locationTypes[i].short_description
        );
        locationTypesDescription += `<b>${title} </b>${description}`;
        if (i < this.locationTypes.length - 1) {
          locationTypesDescription += "<br />";
        }
      }
      return locationTypesDescription;
    },
    getLocationsPaginationLength() {
      return Math.floor(this.locationsAmount / LOCATIONS_REQUEST_LIMIT) + 1;
    },
  },
  methods: {
    changeSelection(index, location) {
      if (!this.currentLocations[index].selected) {
        this.resetSelection();
        this.selectedLocation = {
          content: JSON.stringify({
            de: `${location.title}${
              location.state_name
                ? " (" + JSON.parse(location.state_name)["de"] + ")"
                : ""
            }`,
            en: `${location.title}${
              location.state_name
                ? " (" + JSON.parse(location.state_name)["en"] + ")"
                : ""
            }`,
          }),
          value: {
            type: LOCATION_TYPE_PARTNER,
            data: {
              name: location.enum.trim(),
            },
          },
        };
      } else {
        this.selectedLocation = null;
      }
      this.currentLocations[index].selected =
        !this.currentLocations[index].selected;
      this.save();
    },
    changeLocations() {
      if (this.allLocations[this.currentPage - 1].length > 0) {
        this.currentLocations = this.allLocations[this.currentPage - 1];
      } else {
        this.fetchLocations();
      }
    },
    async fetchAll() {
      await this.fetchLocationTypes();
      this.fetchLocationDetails();
      this.fetchLocationCountries();
    },
    async fetchCityChallengeLocations() {
      try {
        this.loading++;
        this.currentPage = 1;
        this.allLocations = [[]];
        const getParameter = this.getGetParameter();
        let locations = await fetchCityChallengeLocations(
          getParameter,
          this.cityChallengeCountry
        );
        locations.sort((a, b) => {
          return a.sort_order > b.sort_order
            ? -1
            : a.sort_order < b.sort_order
            ? 1
            : 0;
        });
        let selectedIndex = -1;
        for (let i = 0; i < locations.length; ++i) {
          const isSelected =
            selectedIndex < 0 &&
            (this.isPartnerLocationPreSelected(locations[i].enum) ||
              getParameter === locations[i].enum);
          locations[i]["selected"] = false;
          locations[i]["city_name"] = JSON.stringify({
            de: locations[i]["city_name_de"]
              ? locations[i]["city_name_de"]
              : locations[i]["city_name_en"],
            en: locations[i]["city_name_en"],
          });
          if (isSelected) {
            selectedIndex = i;
          }
        }
        this.allLocations[this.currentPage - 1] = locations;
        this.currentLocations = locations;
        if (selectedIndex >= 0) {
          this.changeSelection(
            selectedIndex,
            this.currentLocations[selectedIndex]
          );
        }
        this.loading--;
      } catch (error) {
        this.onError(error);
      }
    },
    async fetchLocationCountries() {
      try {
        this.loading++;
        this.countries = await fetchLocationCountries();
        this.loading--;
      } catch (error) {
        this.onError(error);
      }
    },
    async fetchLocationDetails() {
      this.currentPage = 1;
      await this.fetchLocationsAmount();
      this.allLocations = [];
      for (let i = 0; i < this.getLocationsPaginationLength; ++i) {
        this.allLocations.push([]);
      }
      this.fetchLocations();
    },
    async fetchLocations() {
      try {
        this.loading++;
        const getParameter = this.getGetParameter();
        const countryId = this.country === null ? 0 : parseInt(this.country.id);
        const stateId = this.state === null ? 0 : parseInt(this.state.id);
        let locations = await fetchLocations(
          getParameter.trim(),
          this.getSelectedLocationTypesAsList(),
          countryId,
          stateId,
          LOCATIONS_REQUEST_LIMIT,
          LOCATIONS_REQUEST_LIMIT * (this.currentPage - 1)
        );
        let selectedIndex = -1;
        for (let i = 0; i < locations.length; ++i) {
          const isSelected =
            selectedIndex < 0 &&
            (this.isPartnerLocationPreSelected(locations[i].enum) ||
              getParameter === locations[i].enum);
          locations[i]["selected"] = false;
          locations[i]["city_name"] = JSON.stringify({
            de: locations[i]["city_name_de"]
              ? locations[i]["city_name_de"]
              : locations[i]["city_name_en"],
            en: locations[i]["city_name_en"],
          });
          if (isSelected) {
            selectedIndex = i;
          }
        }
        this.allLocations[this.currentPage - 1] = locations;
        this.currentLocations = locations;
        if (selectedIndex >= 0) {
          this.changeSelection(
            selectedIndex,
            this.currentLocations[selectedIndex]
          );
        }
        this.loading--;
      } catch (error) {
        this.onError(error);
      }
    },
    async fetchLocationsAmount() {
      try {
        this.loading++;
        const countryId = this.country === null ? 0 : parseInt(this.country.id);
        const stateId = this.state === null ? 0 : parseInt(this.state.id);
        let result = await fetchLocationsAmount(
          this.getSelectedLocationTypesAsList(),
          countryId,
          stateId
        );
        this.locationsAmount = result[0].amount;
        this.loading--;
      } catch (error) {
        this.onError(error);
      }
    },
    async fetchLocationStates() {
      if (this.country === null) {
        this.states = [];
      } else {
        try {
          this.loading++;
          this.states = await fetchLocationStates(parseInt(this.country.id));
          this.loading--;
        } catch (error) {
          this.onError(error);
        }
      }
    },
    async fetchLocationTypes() {
      try {
        this.loading++;
        let locationTypes = await fetchLocationTypes();
        for (let i = 0; i < locationTypes.length; ++i) {
          locationTypes[i]["name"] = this.translate(locationTypes[i].title);
          this.selectedLocationTypes.push(locationTypes[i].enum);
        }
        this.locationTypes = locationTypes;
        this.loading--;
      } catch (error) {
        this.onError(error);
      }
    },
    getChipIcon(hotelCategoryId) {
      switch (parseInt(hotelCategoryId)) {
        case HOTEL_CATEGORY_BUSINESS_ID:
          return "mdi-handshake";
        case HOTEL_CATEGORY_CITY_ID:
          return "mdi-city";
        default:
          return "mdi-emoticon";
      }
    },
    getGetParameter() {
      let result = "";
      if (
        this.status.location !== undefined &&
        this.status.location.type === LOCATION_TYPE_PARTNER &&
        this.status.location.data !== undefined &&
        this.status.location.data.name !== undefined
      ) {
        result = this.status.location.data.name;
      } else {
        if (Vue.prototype.$query !== undefined) {
          if (Vue.prototype.$query.location !== undefined) {
            result = Vue.prototype.$query.location;
          }
        }
      }
      return result;
    },
    getSelectedLocationTypesAsList() {
      return `'${this.selectedLocationTypes.join("','")}'`;
    },
    isPartnerLocationPreSelected(location) {
      return (
        this.status.location &&
        this.status.location.type === LOCATION_TYPE_PARTNER &&
        this.status.location.data &&
        this.status.location.data.name.trim() === location.trim()
      );
    },
    onCityChallengeCountry(cityChallengeCountry) {
      if (this.cityChallengeCountry === cityChallengeCountry) {
        return;
      }
      this.cityChallengeCountry = cityChallengeCountry;
      this.fetchCityChallengeLocations();
    },
    onCountry() {
      this.fetchLocationDetails();
      this.fetchLocationStates();
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    onState() {
      this.fetchLocationDetails();
    },
    resetSelection() {
      for (let i = 0; i < this.getLocationsPaginationLength; ++i) {
        for (let j = 0; j < this.allLocations[i].length; ++j) {
          this.allLocations[i][j].selected = false;
        }
      }
    },
    save() {
      this.$emit("save", this.selectedLocation);
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
.countries-container {
  width: 100%;
  max-width: 100% !important;
}
.filter-btn-toggle {
  padding-top: 1.5rem;
}
.filter-container {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  justify-content: center;
}
.hotel-type-info-container {
  margin-top: 1rem;
}
.item-cards-container {
  padding-bottom: 0.5rem;
}
.location-type-description-icon {
  margin-left: 1rem;
}
.partner-location-pagination {
  margin-top: 2rem !important;
}
.partner-location-progress-circle {
  margin-top: 2rem;
}
</style>
