<template>
  <div>
    <h1>{{ getCurrentTitle }}</h1>
    <div class="flex-column flex-center">
      <v-breadcrumbs :items="getCurrentBreadcrumbs" large :key="rerender">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            @click="onBack(item.parentId)"
            :class="`event-mode-chosen-bread-crumb ${
              item.disabled ? '' : 'cursor-pointer'
            }`"
            :disabled="item.disabled"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </div>
    <p class="event-mode-title-text">{{ getCurrentQuestion }}</p>
    <div class="text-center" v-if="loading">
      <v-progress-circular
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </div>
    <event-mode-selection
      v-else
      :hasSpecialItem="hasSpecialItem"
      :items="currentItems"
      v-on:choose="onChoose($event)"
    />
  </div>
</template>

<script>
import Vue from "vue";
import EventModeSelection from "./event-mode/EventModeSelection.vue";
import {
  EVENT_MODE_ONLINE_ID,
  EVENT_MODE_LOCAL_ID,
  EVENT_MODE_ROOT_ID,
  EVENT_MODE_LOCAL_FUN_ID,
  EVENT_MODE_LOCAL,
  EVENT_MODE_LOCAL_FUN,
  EVENT_MODE_ONLINE,
  EVENT_MODE_LOCAL_FUN_AR_RALLYE,
  EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN,
  EVENT_MODE_LOCAL_FUN_CITY_CHALLENGE,
  EVENT_MODE_LOCAL_FUN_TEAM_CHALLENGE,
  PRODUCT_CITY_CHALLENGE,
  PRODUCT_TEAM_CHALLENGE,
  PRODUCT_AR_RALLYE,
  PRODUCT_THE_PERFECT_RUN,
  EVENT_MODE_ONLINE_FUN,
  EVENT_MODE_LOCAL_CONTENT,
  EVENT_MODE_ONLINE_CONTENT,
  EVENT_MODE_LOCAL_FUN_EVENT_MARKETING,
  PRODUCT_EVENT_MARKETING,
} from "../utils/constants";
import { fetchEventCategories, fetchEventCategory } from "../utils/server";
import { translate } from "../utils/helper";
import { eventModeBus } from "../main";

export default {
  name: "EventMode",
  components: {
    EventModeSelection,
  },
  props: ["status", "eventModeBus"],
  data() {
    return {
      currentItems: [],
      currentParentId: EVENT_MODE_ROOT_ID,
      loading: true,
      rerender: 0,
      hasSpecialItem: false,
    };
  },
  watch: {
    currentParentId: function () {
      this.emitEventModeChange();
    },
  },
  mounted() {
    this.checkInitialValues();
    this.fetchEventCategories();
  },
  created() {
    const self = this;
    eventModeBus.$on("back", () => {
      self.onBack();
    });
  },
  computed: {
    getCurrentBreadcrumbs() {
      switch (this.currentParentId) {
        case EVENT_MODE_LOCAL_FUN_ID: {
          return [
            {
              text: this.$t("event-mode.eventtype.local"),
              disabled: false,
              parentId: EVENT_MODE_ROOT_ID,
            },
            {
              text: this.$t("event-mode.focus.fun"),
              disabled: false,
              parentId: EVENT_MODE_LOCAL_ID,
            },
            {
              text: this.$t("event-mode.program.bread-crumb"),
              disabled: true,
              parentId: EVENT_MODE_LOCAL_FUN_ID,
            },
          ];
        }
        case EVENT_MODE_LOCAL_ID: {
          return [
            {
              text: this.$t("event-mode.eventtype.local"),
              disabled: false,
              parentId: EVENT_MODE_ROOT_ID,
            },
            {
              text: this.$t("event-mode.focus.bread-crumb"),
              disabled: true,
              parentId: EVENT_MODE_LOCAL_ID,
            },
          ];
        }
        case EVENT_MODE_ONLINE_ID: {
          return [
            {
              text: this.$t("event-mode.eventtype.online"),
              disabled: false,
              parentId: EVENT_MODE_ROOT_ID,
            },
            {
              text: this.$t("event-mode.focus.bread-crumb"),
              disabled: true,
              parentId: EVENT_MODE_ONLINE_ID,
            },
          ];
        }
        default: {
          return [
            {
              text: this.$t("event-mode.eventtype.bread-crumb"),
              disabled: true,
              parentId: EVENT_MODE_ROOT_ID,
            },
          ];
        }
      }
    },
    getCurrentQuestion() {
      switch (this.currentParentId) {
        case EVENT_MODE_LOCAL_ID:
        case EVENT_MODE_ONLINE_ID:
          return this.$t("event-mode.focus.question");
        case EVENT_MODE_ROOT_ID:
          return this.$t("event-mode.eventtype.question");
        default:
          return undefined;
      }
    },
    getCurrentSummaryName() {
      switch (this.currentParentId) {
        case EVENT_MODE_LOCAL_FUN_ID:
          return "program";
        case EVENT_MODE_LOCAL_ID:
        case EVENT_MODE_ONLINE_ID:
          return "focus";
        case EVENT_MODE_ROOT_ID:
          return "eventtype";
        default:
          return undefined;
      }
    },
    getCurrentTitle() {
      switch (this.currentParentId) {
        case EVENT_MODE_LOCAL_FUN_ID:
          return this.$t("event-mode.program.title");
        case EVENT_MODE_ONLINE_ID:
        case EVENT_MODE_LOCAL_ID:
          return this.$t("event-mode.focus.title");
        case EVENT_MODE_ROOT_ID:
          return this.$t("event-mode.eventtype.title");
        default:
          return undefined;
      }
    },
  },
  methods: {
    checkInitialValues() {
      this.parseGetParameters();
      if (
        this.status.product &&
        this.status.product.length === 1 &&
        (this.status.product[0] === PRODUCT_CITY_CHALLENGE ||
          this.status.product[0] === PRODUCT_TEAM_CHALLENGE ||
          this.status.product[0] === PRODUCT_AR_RALLYE ||
          this.status.product[0] === PRODUCT_THE_PERFECT_RUN ||
          this.status.product[0] === PRODUCT_EVENT_MARKETING)
      ) {
        this.currentParentId = EVENT_MODE_LOCAL_FUN_ID;
      } else if (this.status.focus) {
        if (
          this.status.focus === EVENT_MODE_LOCAL_FUN ||
          this.status.focus === EVENT_MODE_LOCAL_CONTENT
        ) {
          this.currentParentId = EVENT_MODE_LOCAL_ID;
        } else if (
          this.status.focus === EVENT_MODE_ONLINE_FUN ||
          this.status.focus === EVENT_MODE_ONLINE_CONTENT
        ) {
          this.currentParentId = EVENT_MODE_ONLINE_ID;
        }
      } else {
        this.currentParentId = EVENT_MODE_ROOT_ID;
      }
    },
    emitEventModeChange() {
      this.$emit(
        "eventModeChange",
        this.currentParentId !== EVENT_MODE_ROOT_ID
      );
    },
    emitSummary(chosen) {
      const values = this.isLocalFunEventMode(chosen.enum)
        ? [
            {
              title: "product",
              content: [this.getProductFromLocalFunEventMode(chosen.enum)],
            },
          ]
        : [
            {
              title: "product",
              content: [],
            },
            {
              title: this.getCurrentSummaryName,
              content: chosen.enum,
            },
          ];
      this.$emit("summary", {
        key: this.getCurrentSummaryName,
        title: `summary.${this.getCurrentSummaryName}`,
        content: chosen.title,
        values,
      });
    },
    async fetchEventCategories() {
      try {
        this.loading = true;
        this.currentItems = await fetchEventCategories(this.currentParentId);
        this.hasSpecialItem = this.currentItems.length > 3;
        this.loading = false;
        this.rerender++;
      } catch (error) {
        this.onError(error);
      }
    },
    getLocalFunEventModeFromProduct(chosenEnum) {
      switch (chosenEnum) {
        case PRODUCT_CITY_CHALLENGE:
          return EVENT_MODE_LOCAL_FUN_CITY_CHALLENGE;
        case PRODUCT_TEAM_CHALLENGE:
          return EVENT_MODE_LOCAL_FUN_TEAM_CHALLENGE;
        case PRODUCT_AR_RALLYE:
          return EVENT_MODE_LOCAL_FUN_AR_RALLYE;
        case PRODUCT_THE_PERFECT_RUN:
          return EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN;
        case PRODUCT_EVENT_MARKETING:
          return EVENT_MODE_LOCAL_FUN_EVENT_MARKETING;
      }
      return "";
    },
    getProductFromLocalFunEventMode(chosenEnum) {
      switch (chosenEnum) {
        case EVENT_MODE_LOCAL_FUN_CITY_CHALLENGE:
          return PRODUCT_CITY_CHALLENGE;
        case EVENT_MODE_LOCAL_FUN_TEAM_CHALLENGE:
          return PRODUCT_TEAM_CHALLENGE;
        case EVENT_MODE_LOCAL_FUN_AR_RALLYE:
          return PRODUCT_AR_RALLYE;
        case EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN:
          return PRODUCT_THE_PERFECT_RUN;
        case EVENT_MODE_LOCAL_FUN_EVENT_MARKETING:
          return PRODUCT_EVENT_MARKETING;
      }
      return "";
    },
    onBack(parentId) {
      if (parentId === undefined || parentId === null) {
        const currentBreadCrumbs = this.getCurrentBreadcrumbs;
        if (!currentBreadCrumbs || currentBreadCrumbs.length < 2) {
          this.currentParentId = EVENT_MODE_ROOT_ID;
        } else {
          this.currentParentId =
            currentBreadCrumbs[currentBreadCrumbs.length - 2].parentId;
        }
      } else {
        this.currentParentId = parentId;
      }
      this.fetchEventCategories();
    },
    isInnerEventModeSelection(chosenEnum) {
      switch (chosenEnum) {
        case EVENT_MODE_ONLINE:
        case EVENT_MODE_LOCAL:
        case EVENT_MODE_LOCAL_FUN:
          return true;
      }
      return false;
    },
    isLocalFunEventMode(chosenEnum) {
      switch (chosenEnum) {
        case EVENT_MODE_LOCAL_FUN_CITY_CHALLENGE:
        case EVENT_MODE_LOCAL_FUN_TEAM_CHALLENGE:
        case EVENT_MODE_LOCAL_FUN_AR_RALLYE:
        case EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN:
        case EVENT_MODE_LOCAL_FUN_EVENT_MARKETING:
          return true;
      }
      return false;
    },
    next(chosenEventMode) {
      this.$emit("next", chosenEventMode);
    },
    onChoose(chosen) {
      let step = 0;
      if (this.$route.query["step"]) {
        step = parseInt(this.$route.query["step"]);
      }
      step++;
      this.$router.push(
        this.$route.path +
          "?language=" +
          this.$route.query["language"] +
          "&step=" +
          step
      );
      this.emitSummary(chosen);
      if (this.isInnerEventModeSelection(chosen.enum)) {
        this.currentParentId = parseInt(chosen.id);
        this.fetchEventCategories();
      } else {
        this.next(chosen);
      }
    },
    onError(error) {
      console.log(error);
      this.$emit("error");
    },
    async parseEventTypeParam(eventtype) {
      switch (eventtype) {
        case EVENT_MODE_LOCAL:
        case EVENT_MODE_ONLINE: {
          let item = await fetchEventCategory(eventtype);
          this.currentParentId = EVENT_MODE_ROOT_ID;
          this.onChoose(item[0]);
          break;
        }
        default:
          return null;
      }
    },
    async parseFocusParam(focus, parentId) {
      const item = await fetchEventCategory(focus);
      this.currentParentId = parentId;
      this.onChoose(item[0]);
    },
    async parseFocusParams(focus) {
      switch (focus) {
        case EVENT_MODE_LOCAL_FUN:
        case EVENT_MODE_LOCAL_CONTENT:
          {
            await this.parseEventTypeParam(EVENT_MODE_LOCAL);
            await this.parseFocusParam(focus, EVENT_MODE_LOCAL_ID);
          }
          break;
        case EVENT_MODE_ONLINE_FUN:
        case EVENT_MODE_ONLINE_CONTENT:
          {
            await this.parseEventTypeParam(EVENT_MODE_ONLINE);
            await this.parseFocusParam(focus, EVENT_MODE_ONLINE_ID);
          }
          break;
        default:
          return null;
      }
    },
    async parseGetParameters() {
      if (Vue.prototype.$query !== undefined) {
        this.loading = true;
        if (Vue.prototype.$query.eventtype !== undefined) {
          await this.parseEventTypeParam(Vue.prototype.$query.eventtype);
          Vue.prototype.$query.eventtype = undefined;
        }
        if (Vue.prototype.$query.focus !== undefined) {
          await this.parseFocusParams(Vue.prototype.$query.focus);
          Vue.prototype.$query.focus = undefined;
        }
        if (Vue.prototype.$query.product !== undefined) {
          const products = Vue.prototype.$query.product.split(",");
          const eventModeEnum = this.getLocalFunEventModeFromProduct(
            products[0]
          );
          if (
            products.length === 1 &&
            this.isLocalFunEventMode(eventModeEnum)
          ) {
            await this.parseFocusParams(EVENT_MODE_LOCAL_FUN);
            let item = await fetchEventCategory(eventModeEnum);
            this.currentParentId = EVENT_MODE_LOCAL_FUN_ID;
            this.onChoose(item[0]);
            Vue.prototype.$query.product = undefined;
          }
        }
        this.loading = false;
      }
    },
    translate(content) {
      return translate(content, this);
    },
  },
};
</script>

<style scoped>
.event-mode-chosen-bread-crumb {
  color: var(--secondary);
}
.event-mode-chosen-bread-crumb:hover {
  color: var(--primary);
}
.event-mode-title-text {
  text-align: center;
  font-size: larger;
  margin-bottom: 2rem;
}
h1 {
  text-align: center;
}
</style>
