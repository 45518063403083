<template>
  <v-form v-model="valid">
    <v-text-field
      :disabled="disabled === true"
      :rules="[rules.required, isTheLowest, isTheHighest]"
      @change="checkBoundaries()"
      class="number-input"
      v-model="number"
      type="number"
      :label="label"
      append-outer-icon="mdi-plus"
      @click:append-outer="increment"
      prepend-icon="mdi-minus"
      @click:prepend="decrement"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  name: "MyNumberInput",
  props: ["label", "min", "max", "default", "disabled"],
  data() {
    return {
      valid: false,
      number: 2,
      isLowest: false,
      isHighest: false,
      rules: {
        required: (value) =>
          !!value || this.$t("data.validation.phone.required"),
      },
    };
  },
  watch: {
    default: function () {
      this.number = this.default;
    },
  },
  mounted() {
    if (this.default !== undefined) {
      this.number = this.default;
    } else if (this.min !== undefined) {
      this.number = this.min;
    } else if (this.max !== undefined) {
      this.number = this.max;
    }
    this.save();
  },
  computed: {
    isTheLowest() {
      if (this.min) {
        return (
          !this.isLowest ||
          `${this.min} ${this.$t("data.participants.min-reached")}`
        );
      }
      return true;
    },
    isTheHighest() {
      if (this.max) {
        return (
          !this.isHighest ||
          `${this.max} ${this.$t("data.participants.max-reached")}`
        );
      }
      return true;
    },
  },
  methods: {
    checkBoundaries() {
      if (this.min && this.number < this.min) {
        this.number = this.min;
        this.resetValidators();
      } else if (this.max && this.number > this.max) {
        this.number = this.max;
        this.resetValidators();
      }
      this.save();
    },
    increment() {
      if (this.max && this.number >= this.max) {
        this.isHighest = true;
      } else {
        this.isHighest = false;
        this.number = parseInt(this.number, 10) + 1;
      }
      this.isLowest = false;
      this.save();
    },
    decrement() {
      if (this.min && this.number <= this.min) {
        this.isLowest = true;
      } else {
        this.isLowest = false;
        this.number = parseInt(this.number, 10) - 1;
      }
      this.isHighest = false;
      this.save();
    },
    resetValidators() {
      this.isLowest = false;
      this.isHighest = false;
    },
    save() {
      this.$emit("change", this.number);
    },
  },
};
</script>
<style scoped>
/* REMOVE SMALL NUMBER INPUT ARROWS */
.number-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number-input >>> input::-webkit-outer-spin-button,
.number-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
