<template>
  <div>
    <h1>{{ $t("data.title") }}</h1>
    <v-form v-model="valid">
      <v-container>
        <v-row v-if="!isLocalEvent">
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <my-number-input
              :label="$t('data.participants.amount')"
              :min="2"
              :max="getParticipantsMax"
              :default="participants"
              :ruleText="$t('data.validation.participants.required')"
              v-on:change="participants = $event"
            />
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="8" xl="8">
            <v-textarea
              v-model="goalOfEvent"
              clearable
              :label="$t('data.goal-of-event')"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            v-if="isLocalEvent && !isEventMarketing"
          >
            <my-number-input
              :label="$t('data.participants.amount')"
              :min="2"
              :max="getParticipantsMax"
              :default="participants"
              :ruleText="$t('data.validation.participants.required')"
              v-on:change="participants = $event"
            />
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            class="data-event-date-col"
          >
            <my-date-picker
              :label="$t('data.date')"
              :initialDate="date"
              v-on:save="saveDate($event)"
            />
            <span v-if="isHotDate" class="data-hot-date-span"
              >{{ $t("data.hot-date-text") }}
              <a :href="getAvailabilityLink" target="_blank">{{
                $t("data.hot-date-link")
              }}</a></span
            >
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <my-time-picker
              :label="$t('data.time')"
              :initialTime="time"
              v-on:save="saveTime($event)"
            />
          </v-col>
          <v-col
            cols="12"
            xs="12"
            sm="6"
            md="6"
            lg="4"
            xl="4"
            v-if="!isLocalEvent"
          >
            <v-autocomplete
              :label="$t('data.timezone')"
              clearable
              :items="getTimeZones"
              v-model="timezone"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="isLocalEvent">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <v-textarea
              v-model="goalOfEvent"
              clearable
              :label="$t('data.goal-of-event')"
              auto-grow
              rows="1"
            ></v-textarea>
          </v-col>
        </v-row>
        <div class="data-spacer"></div>
        <v-row>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-text-field
              v-model="firstName"
              :rules="firstNameRules"
              :label="$t('data.first-name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-text-field
              v-model="lastName"
              :rules="lastNameRules"
              :label="$t('data.last-name')"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-text-field
              v-model="companyName"
              :label="$t('data.company-name')"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" xs="6" sm="3" md="3" lg="2" xl="2">
            <span class="data-languages-label">{{
              $t("data.preferred-language")
            }}</span>
            <div class="countries-container flex-row">
              <country-button
                :asset="require('./../assets/german-flag.svg')"
                :country="LOCALE_GERMAN"
                :selectedCountry="language"
                v-on:click="onLanguage(LOCALE_GERMAN)"
                :small="true"
              />
              <country-button
                :asset="require('./../assets/british-flag.svg')"
                :country="LOCALE_ENGLISH"
                :selectedCountry="language"
                v-on:click="onLanguage(LOCALE_ENGLISH)"
                :small="true"
              />
            </div>
          </v-col>
          <v-col cols="6" xs="6" sm="3" md="3" lg="2" xl="2">
            <v-autocomplete
              :label="$t('data.phone-prefix')"
              v-model="phonePrefix"
              :rules="phonePrefixRules"
              :items="getPhoneNumberPrefixes"
              item-text="name"
              item-value="prefix"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-text-field
              class="number-input"
              v-model="phone"
              :rules="phoneRules"
              :label="$t('data.phone')"
              required
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$t('data.email')"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <div class="data-spacer"></div>
        <v-row>
          <v-checkbox
            class="data-email-contact-allowed"
            v-model="emailContactAllowed"
            color="primary"
          >
            <template v-slot:label>
              <div>
                {{ $t("data.email-contact-allowed.head") }}
                <a target="_blank" :href="getPrivacyLink" @click.stop>{{
                  $t("data.email-contact-allowed.body")
                }}</a
                >{{ $t("data.email-contact-allowed.tail") }}
              </div>
            </template>
          </v-checkbox>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import CountryButton from "../gui-components/CountryButton.vue";
import MyDatePicker from "../gui-components/MyDatePicker.vue";
import MyNumberInput from "../gui-components/MyNumberInput.vue";
import MyTimePicker from "../gui-components/MyTimePicker.vue";
import { dataBus } from "../main";
import {
  buildRedirectLink,
  guessPhonePrefix,
  guessTimezone,
  translate,
} from "../utils/helper";
import { fetchCountryCallingCodes } from "../utils/server";

import {
  DATA_PARTICIPANTS_MAX_LOCAL,
  DATA_PARTICIPANTS_MAX_ONLINE,
  EVENT_MODE_LOCAL,
  DATA_PARTICIPANTS_DEFAULT,
  PRODUCT_EVENT_MARKETING,
  LOCALE_GERMAN,
  LOCALE_ENGLISH,
  PRODUCT_CHRISTMAS_TOWN,
} from "./../utils/constants";

const momentTimezone = require("moment-timezone");

export default {
  name: "Data",
  components: { CountryButton, MyDatePicker, MyNumberInput, MyTimePicker },
  props: ["memory", "status"],
  data() {
    return {
      isHotDate: false,
      firstName: "",
      LOCALE_GERMAN,
      LOCALE_ENGLISH,
      language: "",
      firstNameRules: [
        (v) => !!v || this.$t("data.validation.first-name.required"),
      ],
      lastName: "",
      lastNameRules: [
        (v) => !!v || this.$t("data.validation.last-name.required"),
      ],
      companyName: "",
      email: "",
      emailRules: [
        (v) => !!v || this.$t("data.validation.e-mail.required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("data.validation.e-mail.valid"),
      ],
      phonePrefix: "",
      phonePrefixRules: [
        (v) => !!v || this.$t("data.validation.phone-prefix.required"),
      ],
      phone: "",
      phoneRules: [
        (v) => !!v || this.$t("data.validation.phone.required"),
        (v) => /[1-9]+[0-9]*/.test(v) || this.$t("data.validation.phone.valid"),
      ],
      date: null,
      time: null,
      timeMenu: false,
      participants: DATA_PARTICIPANTS_DEFAULT,
      goalOfEvent: "",
      emailContactAllowed: false,
      timezone: null,
      phonePrefixes: [],
      isEventMarketing: false,
      isLocalEvent: true,
      isUnsupervised: false,
      valid: false,
    };
  },
  mounted() {
    this.isEventMarketing =
      this.status.product &&
      this.status.product.length === 1 &&
      this.status.product[0] === PRODUCT_EVENT_MARKETING;
    this.isLocalEvent = this.status.eventtype === EVENT_MODE_LOCAL;
    this.isUnsupervised =
      this.status.product &&
      this.status.product.length === 1 &&
      this.status.product[0] === PRODUCT_CHRISTMAS_TOWN;
    if (!this.isLocalEvent) {
      this.timezone = guessTimezone();
    }
    this.phonePrefix = guessPhonePrefix();
    this.checkInitialValues();
  },
  updated() {
    this.$emit("memory", {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      companyName: this.companyName.trim(),
      email: this.email.trim(),
      language: this.language,
      phone: this.phone.trim(),
      phonePrefix: this.phonePrefix,
      participants: this.participants,
      date: this.date,
      time: this.time,
      timezone: this.timezone,
      goalOfEvent: this.goalOfEvent.trim(),
    });
  },
  watch: {
    phone: function () {
      while (this.phone.indexOf("0") === 0) {
        this.phone = this.phone.substr(1);
      }
    },
    emailContactAllowed: function () {
      this.emitDataChange();
    },
    valid: function () {
      this.emitDataChange();
    },
  },
  created() {
    const self = this;
    dataBus.$on("next", () => {
      self.onContinue();
    });
    this.emitDataChange();
    this.fetchPhonePrefixes();
  },
  computed: {
    getAvailabilityLink() {
      return buildRedirectLink(
        this.$i18n.locale,
        "https://www.teamazing.com/availabilities/"
      );
    },
    getParticipantsMax() {
      if (this.isLocalEvent) {
        return DATA_PARTICIPANTS_MAX_LOCAL;
      }
      return DATA_PARTICIPANTS_MAX_ONLINE;
    },
    getPhoneNumberPrefixes() {
      let result = [];
      this.phonePrefixes.forEach((entry) => {
        result.push({
          name: `${entry.phone_prefix} (${translate(
            entry.country_name,
            this
          )})`,
          prefix: entry.phone_prefix,
        });
      });
      return result;
    },
    getPrivacyLink() {
      return buildRedirectLink(
        this.$i18n.locale,
        "https://www.teamazing.com/privacy-policy/"
      );
    },
    getTimeZones() {
      return momentTimezone.tz.names();
    },
  },
  methods: {
    buildGoalOfEvent() {
      return this.goalOfEvent.trim().length === 0
        ? undefined
        : this.goalOfEvent.trim();
    },
    buildPhone() {
      return (
        this.phonePrefix.replace("+", "00").replace(/ /g, "") +
        this.phone.trim()
      );
    },
    buildUserDate() {
      return this.date === null ? undefined : this.date;
    },
    buildUserTime() {
      if (this.time === null) {
        return undefined;
      }
      if (this.timezone === null || this.date === null) {
        return this.time;
      }
      return momentTimezone
        .tz(`${this.date} ${this.time}`, this.timezone)
        .format()
        .substr(11);
    },
    buildUTCDate() {
      if (this.timezone === null || this.date === null || this.time === null) {
        return undefined;
      }
      return momentTimezone
        .tz(`${this.date} ${this.time}`, this.timezone)
        .utc()
        .format()
        .substr(0, 10);
    },
    buildUTCTime() {
      if (this.timezone === null || this.date === null || this.time === null) {
        return undefined;
      }
      return momentTimezone
        .tz(`${this.date} ${this.time}`, this.timezone)
        .utc()
        .format()
        .substr(11);
    },
    buildTimezone() {
      return this.timezone === null ? undefined : this.timezone;
    },
    checkInitialValues() {
      if (this.memory) {
        this.checkInitialValue("firstName");
        this.checkInitialValue("lastName");
        this.checkInitialValue("companyName");
        this.checkInitialValue("email");
        this.checkInitialValue("language");
        this.checkInitialValue("phone");
        this.checkInitialValue("phonePrefix");
        this.checkInitialValue("participants");
        this.checkInitialValue("date");
        this.checkInitialValue("time");
        this.checkInitialValue("timezone");
        this.checkInitialValue("goalOfEvent");
      }
    },
    checkInitialValue(key) {
      if (this.memory[key]) {
        this[key] = this.memory[key];
      }
    },
    emitDataChange() {
      this.$emit("dataChange", !this.emailContactAllowed || !this.valid);
    },
    emitSummary() {
      const values = [
        {
          title: "data",
          content: {
            first_name: this.firstName.trim(),
            last_name: this.lastName.trim(),
            company: this.companyName.trim(),
            language: this.language,
            email: this.email.trim(),
            phone: this.buildPhone(),
            participants: this.participants,
            user_date: this.buildUserDate(),
            user_time: this.buildUserTime(),
            utc_date: this.buildUTCDate(),
            utc_time: this.buildUTCTime(),
            timezone: this.buildTimezone(),
            text: this.buildGoalOfEvent(),
          },
        },
      ];
      this.$emit("summary", {
        key: "data",
        title: "steps.data",
        content: undefined,
        values,
      });
      this.$emit("next");
    },
    async fetchPhonePrefixes() {
      this.phonePrefixes = await fetchCountryCallingCodes();
    },
    getCalendarType() {
      var calendar = "LOCAL-AT";
      if (!this.isLocalEvent) {
        calendar = "ONLINE";
      } else if (this.phonePrefix == "+49") {
        calendar = "LOCAL-DE";
      }
      return calendar;
    },
    isCompany() {
      return this.company.trim().length > 0 ? this.company.trim() : undefined;
    },
    onContinue() {
      this.emitSummary();
    },
    onLanguage(language) {
      if (this.language === language) {
        this.language = "";
        return;
      }
      this.language = language;
    },
    async saveDate(date) {
      this.date = date;
      if (!this.isUnsupervised) {
        try {
          var calendar = this.getCalendarType();
          const result = await axios.get(
            `https://app.teamazing.com/api/availability?date=${date}&calendar=${calendar}`,
            this.result
          );

          if (result.status !== 200) {
            console.log(JSON.stringify(result));
          } else {
            this.isHotDate = result.data == "HOT-DATE";
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        this.isHotDate = false;
      }
    },
    saveTime(time) {
      this.time = time;
    },
  },
};
</script>

<style scoped>
/* REMOVE SMALL NUMBER INPUT ARROWS */
.number-input >>> input[type="number"] {
  -moz-appearance: textfield;
}
.number-input >>> input::-webkit-outer-spin-button,
.number-input >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.data-email-contact-allowed {
  margin-left: 0.5rem;
}

.data-event-date-col {
  display: flex;
  flex-direction: column;
  font-size: small;
  color: var(--primary);
  text-align: center;
}

.data-hot-date-span {
  margin-top: -1rem;
  z-index: 7;
}
.data-languages-label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}
.data-spacer {
  height: 3rem;
}
h1 {
  text-align: center;
  margin-bottom: 2rem;
}
p {
  font-size: larger;
  text-align: center;
  margin-bottom: 2rem;
}
</style>
