import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#ff0066",
        secondary: "#434343",
        accent: "#007ffd",
        error: "#b71c1c",
        taPink: "#EA335D",
        taGreen: "#BCEB4A",
        taBlue: "#70BEE9",
        teamazingMagenta: "#ff0066",
        cultureGray: "#f5f5f7",
        unifyAzure: "#007ffd",
        black3: "#6E6E73",
      },
    },
  },
});
