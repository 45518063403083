import moment from "moment";
const momentTimezone = require("moment-timezone");

import {
  COUNTRY_ABBR_AUSTRIA,
  COUNTRY_ABBR_GERMANY,
  DOMAIN_AT,
  DOMAIN_COM,
  DOMAIN_DE,
  LOCALE_ENGLISH,
  LOCALE_GERMAN,
} from "./constants";

export function buildRedirectLink(locale, link) {
  return `${link}?redirect&language=${locale}&domain=${guessDomainWithLanguage(
    locale
  )}`;
}

export function concatListToEnumeration(list) {
  if (list.length === 0) {
    return "";
  }

  if (list.length === 1) {
    return list[0];
  }

  let result = list[0];

  for (let i = 1; i < list.length - 1; i++) {
    result += ", " + list[i];
  }

  return result + " & " + list[list.length - 1];
}

export function guessCountry() {
  const citiesInAustria = ["Europe/Vienna"];

  if (citiesInAustria.includes(guessTimezone())) {
    return COUNTRY_ABBR_AUSTRIA;
  }

  return COUNTRY_ABBR_GERMANY;

}

function getUserBrowserLanguage() {
  const userLanguage = navigator.language || navigator.userLanguage;
  return userLanguage;
}

function guessDomain() {
  const browserLanguage = getUserBrowserLanguage();
  const citiesInAustria = ["Europe/Vienna"];
  const citiesInGermany = ["Europe/Berlin", "Europe/Busingen"];
  const timezone = guessTimezone();

  if(browserLanguage == "de-AT") {
    return DOMAIN_AT;
  } else if(browserLanguage == "de-DE" || browserLanguage.includes("de")) {
    return DOMAIN_DE;
  } else if(browserLanguage.includes("en")) {
    return DOMAIN_COM;
  } else {
    if (citiesInAustria.includes(timezone)) {
      return DOMAIN_AT;
    } else if (citiesInGermany.includes(timezone)) {
      return DOMAIN_DE;
    }
  }

  return DOMAIN_COM;
}


export function guessDomainWithLanguage(locale) {
  let domain = guessDomain();
  const browserLanguage = getUserBrowserLanguage();
 
  //alert("LOCALE="+locale);
  //alert("GUESSLOCALE="+guessLocale());
  //alert("LOCALE_ENGLISCH="+LOCALE_ENGLISH);

  if (locale !== guessLocale()) {
    if (locale === LOCALE_ENGLISH) {
      //alert("ERGEBNISS ====="+DOMAIN_COM);
      domain = DOMAIN_COM;
    } else {
      if(browserLanguage.includes("-AT")) {
        //alert(DOMAIN_AT);
        domain = DOMAIN_AT;
      } else {
        //alert(DOMAIN_DE);
        domain = DOMAIN_DE;
      }
      
    }
  }

  console.log("GUESSLOCALE==="+guessLocale());
  console.log("LOCALE==="+locale);
  console.log("DOMAIN==="+domain);

  return domain;
}

export function guessLocale() {
  const browserLanguage = getUserBrowserLanguage();
  const citiesWithGermanLanguage = [
    "Europe/Berlin",
    "Europe/Busingen",
    "Europe/Vaduz",
    "Europe/Zurich",
    "Europe/Vienna",
  ];

  if(browserLanguage.includes("de")) {
   
    return LOCALE_GERMAN;
  } else if(browserLanguage.includes("en")) {

    return LOCALE_ENGLISH;
  } else {
    if (citiesWithGermanLanguage.includes(guessTimezone())) {

      return LOCALE_GERMAN;
    }

    return LOCALE_ENGLISH;
  }
}

export function guessPhonePrefix() {
  if (guessCountry() === COUNTRY_ABBR_AUSTRIA) {
    return "+43";
  }
  return "+49";
}

export function guessTimezone() {
  return momentTimezone.tz.guess(true);
}

export function hoursToDisplay(locale, hours) {
  return timeToLocaleString(locale, `${hours}h`);
}

export function hoursToMinutes(hours) {
  return hours * 60;
}

export function minutesToHours(minutes) {
  return parseFloat(minutes) / 60;
}

export function timeToLocaleString(locale, timeString) {
  if (locale === "de") {
    return timeString.replaceAll(".", ",");
  }
  return timeString;
}

export function timesToShortDisplay(locale, fromTimeString, toTimeString) {
  if (fromTimeString === null && toTimeString === null) {
    return null;
  }
  if (fromTimeString === null) {
    return timeToShortDisplay(locale, toTimeString);
  }
  if (toTimeString === null) {
    return timeToShortDisplay(locale, fromTimeString);
  }
  return timeToLocaleString(
    locale,
    `${timeToHours(fromTimeString)}-${timeToHours(toTimeString)}h`
  );
}

export function timeToShortDisplay(locale, timeString) {
  return timeToLocaleString(locale, `${timeToHours(timeString)}h`);
}

export function timeToHours(timeString) {
  const timeObject = moment(timeString, "hh:mm:ss");
  return (
    timeObject.hours() +
    (timeObject.minutes() > 0 ? timeObject.minutes() / 60 : 0)
  );
}

export function translate(content, self) {
  if (content !== undefined) {
    try {
      return JSON.parse(content)[self.$i18n.locale];
    } catch (error) {
      console.log(
        `Unable to translate the following text: "${content}"\n\n${error}`
      );
    }
  }
}
