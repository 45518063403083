import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.prototype.$query = {};

export const navigationBus = new Vue();
export const eventModeBus = new Vue();
export const selectionBus = new Vue();
export const upgradesBus = new Vue();
export const locationBus = new Vue();
export const dataBus = new Vue();

new Vue({
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
