export const EVENT_MODE_ONLINE = "ONLINE";
export const EVENT_MODE_LOCAL = "LOCAL";
export const EVENT_MODE_LOCAL_FUN = "LOCAL-FUN";
export const EVENT_MODE_LOCAL_CONTENT = "LOCAL-CONTENT";
export const EVENT_MODE_LOCAL_FUN_TEAM_CHALLENGE = "LOCAL-FUN-TEAM-CHALLENGE";
export const EVENT_MODE_LOCAL_FUN_AR_RALLYE = "LOCAL-FUN-AR-RALLYE";
export const EVENT_MODE_LOCAL_FUN_THE_PERFECT_RUN = "LOCAL-FUN-THE-PERFECT-RUN";
export const EVENT_MODE_LOCAL_FUN_CITY_CHALLENGE = "LOCAL-FUN-CITY-CHALLENGE";
export const EVENT_MODE_LOCAL_FUN_EVENT_MARKETING = "LOCAL-FUN-EVENTMARKETING";
export const EVENT_MODE_ONLINE_FUN = "ONLINE-FUN";
export const EVENT_MODE_ONLINE_CONTENT = "ONLINE-CONTENT";

export const EVENT_MODE_ROOT_ID = 0;
export const EVENT_MODE_ONLINE_ID = 1;
export const EVENT_MODE_LOCAL_ID = 2;
export const EVENT_MODE_LOCAL_FUN_ID = 3;
export const EVENT_MODE_LOCAL_CONTENT_ID = 4;
export const EVENT_MODE_ONLINE_FUN_ID = 8;
export const EVENT_MODE_ONLINE_CONTENT_ID = 9;

export const EVENT_MODE_FUN_ID = 1;
export const EVENT_MODE_CONTENT_ID = 2;

export const LOCATION_TYPE_PARTNER = "PARTNER";
export const LOCATION_TYPE_WISH = "CUSTOM";

export const TEAM_CHALLENGE_PRODUCT_ID = 1;
export const CITY_CHALLENGE_PRODUCT_ID = 3;

export const HOTEL_CATEGORY_BUSINESS_ID = 1;
export const HOTEL_CATEGORY_CASUAL_ID = 2;
export const HOTEL_CATEGORY_CITY_ID = 3;

export const COUNTRY_ABBR_AUSTRIA = "AT";
export const COUNTRY_ABBR_GERMANY = "DE";

export const PHONE_PREFIX_AUSTRIA = "+43";
export const PHONE_PREFIX_GERMANY = "+49";

export const LOCALE_GERMAN = "de";
export const LOCALE_ENGLISH = "en";

export const DOMAIN_AT = "at";
export const DOMAIN_DE = "de";
export const DOMAIN_COM = "com";

export const DATA_PARTICIPANTS_DEFAULT = 25;
export const DATA_PARTICIPANTS_MAX_LOCAL = 1000;
export const DATA_PARTICIPANTS_MAX_ONLINE = 10000000000;

export const PRODUCT_TEAM_CHALLENGE = "TEAM-CHALLENGE";
export const PRODUCT_AR_RALLYE = "AR-RALLYE";
export const PRODUCT_THE_PERFECT_RUN = "THE-PERFECT-RUN";
export const PRODUCT_CITY_CHALLENGE = "CITY-CHALLENGE";
export const PRODUCT_EVENT_MARKETING = "EVENTMARKETING";
export const PRODUCT_CHRISTMAS_TOWN = "CHRISTMAS-TOWN";

export const PRODUCT_CATEGORY_GAME = "GAME";
export const PRODUCT_CATEGORY_WORKSHOP = "WORKSHOP";
export const PRODUCT_CATEGORY_ADD_ON = "ADD-ON";

export const LOCAL_STORAGE_COOKIE_NOTICE_ACCEPTED =
  "LOCAL_STORAGE_COOKIE_NOTICE_ACCEPTED";
export const LOCAL_STORAGE_GUID = "LOCAL_STORAGE_GUID";

export const ERROR_SNACKBAR_DURATION = 10000;
export const ERROR_SERVER = "Server error: Contact support!";
